@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/picto';

.Summary {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.2rem 2rem 2rem 2rem;
    overflow: hidden;

    &[data-type='preview'] {
      background-color: $color-primary-01-200;
    }

    &[data-type='details'] {
      background-color: $color-grey-100;
    }
  }

  &__title {
    text-align: left;
    font-weight: bold;
    position: relative;
    font-size: 2rem;

    h2 {
      font-weight: inherit;
    }

    &:is(button) {
      display: flex;
      align-items: center;
      gap: 1ch;
      @include picto('arrow--down', 'after');

      &::after {
        margin-left: auto;
        font-size: 0.5em;
        transition: transform 0.2s ease;
      }
    }

    &[class*='open']::after {
      transform: rotate(180deg);
    }
  }

  &__card {
    width: 100%;
    display: block;
    aspect-ratio: 85/54;
    object-fit: cover;
    border-radius: 2rem;
    background-color: $color-grey-100;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    font-size: 1.6rem;

    &__line {
      font-weight: 100;
      display: flex;
      gap: 1ch;

      strong {
        font-weight: inherit;
        margin-left: auto;
      }
    }

    &__total {
      font-weight: bold;
      display: flex;
      gap: 1ch;
      margin-inline: -3.2rem;
      padding-inline: 3.2rem;
      margin-top: 1.6rem;
      padding-top: 1.6rem;
      border-top: 0.1rem solid $color-grey-200;

      strong {
        margin-left: auto;
      }
    }
  }

  @include over-mobile {
    &__block {
      padding: 1.6rem 3.2rem 3.2rem 3.2rem;

      &__title {
        font-size: 2.2rem;
      }
    }
  }
}

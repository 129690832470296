@import 'styles/variables/colors';
@import 'styles/mixins/picto';

.ReviewForm {
  display: flex;
  gap: 1.6rem;
  flex-direction: column;

  &__product {
    display: flex;
    padding: 1.6rem;
    gap: 1.6rem;
    align-items: flex-start;
    border: 0.1rem solid $color-grey-200;

    &__image {
      width: 20%;
      aspect-ratio: 1/1;
      object-fit: cover;
      object-position: center;
    }

    &__title {
      flex-grow: 1;
      font-size: 1.6rem;
      font-weight: bold;

      span {
        font-weight: 100;
        font-size: 1.4rem;
        display: block;
      }
    }
  }

  &__form {
    padding-block: 3.2rem;
    padding-inline: 1.6rem;
    background-color: $color-primary-01-100;

    &__description {
      display: block;
      margin-bottom: 1.6rem;
      font-style: italic;
      font-weight: 100;
      font-size: 1.4rem;
    }

    &__tooltip {
      border-radius: 100%;
      font-size: 1.6rem;
      width: 2em;
      aspect-ratio: 1/1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $color-primary-01-300;

      &__inner {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        h2 {
          font-weight: bold;
          font-size: 1.4rem;
        }

        ul {
          font-weight: 100;
          font-size: 1.3rem;

          li {
            display: flex;
            gap: 0.65em;
            margin-left: 0.25em;
            @include picto('check', 'before');

            &::before {
              flex-shrink: 0;
              margin-right: 0;
              margin-top: 0.25em;
            }
          }
        }
      }
    }
  }

  &__confirm {
    padding-block: 3.2rem;
    padding-inline: 1.6rem;
    background-color: $color-primary-01-100;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    &__title {
      font-size: 2rem;
      font-weight: bold;
    }

    &__subtitle {
      font-weight: bold;
      font-size: 1.6rem;
    }

    &__review {
      display: flex;
      gap: 3.2rem;

      &__stars {
        flex-shrink: 0;
        margin-top: 1rem;
      }

      &__details {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
    }
  }
}

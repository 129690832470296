@import 'styles/mixins/media-queries';

.RappelProduits {
  &__title {
    margin-bottom: 6.4rem;
    font-size: 2.8rem;
    font-weight: bold;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;

    &__image {
      display: block;
      max-width: 40rem;
      max-height: 40rem;
      height: auto;
      margin-bottom: 5rem;
    }

    &__text {
      table tr {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  @include over-mobile {
    &__item {
      max-width: 98rem;
      margin: auto;
      flex-direction: row;
      gap: 4rem;

      &__image {
        width: calc(40% - 4rem);
      }

      &__text {
        width: 60%;
        flex-shrink: 0;

        table tr {
          flex-direction: row;
          align-items: center;

          td:last-of-type {
            flex-grow: 1;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

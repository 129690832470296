@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';

.TextImage {
  color: black;
  @extend .container;
  display: flex;
  grid-gap: 10rem;
  place-items: center;
  margin-bottom: 8rem;
  margin-top: 8rem;

  &__left {
    flex-direction: row-reverse;
    &__text {
      margin-left: 4rem;

      @include on-mobile {
        margin-left: 0;
      }
    }
  }

  @include on-mobile {
    flex-direction: column-reverse;
    gap: 4rem;
  }

  &__text {
    h2 {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 3rem;
    }
    h3 {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.4rem;
      margin-bottom: 2rem;
    }
  }

  img {
    aspect-ratio: 8/7;
    object-fit: cover;
    @include on-mobile {
      grid-row: 1;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.addressForm {
  background: white;
  padding: 3rem;
  text-align: left;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 3rem;
  h2 {
    margin-top: 2rem;
  }
  h3 {
    margin-bottom: 3rem;
    font-size: 1.75rem;
  }
  div {
    text-align: left;
  }

  &__gender-form {
    margin-top: 1rem;
    div:first-of-type {
      display: flex;
      flex-direction: column;
    }
  }
  label {
    font-size: 1.6rem;
    color: black;
  }

  &__button {
    text-align: center;
    max-width: 32rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
  }
  &__fields {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    margin-bottom: 4rem;

    @include over-mobile {
      grid-template-columns: repeat(2, 1fr);
    }

    &__checks {
      display: flex;
      flex-direction: column;
    }
  }
}

@import 'styles/variables/colors';

.CheckoutSummary {
  background-color: $color-grey-100;
  padding: 1.6rem;

  hr {
    border-color: $color-grey-200;
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: bold;
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;
    border-bottom: 0.1rem solid $color-grey-200;
  }

  &__line {
    font-size: 1.4rem;
    line-height: 1.1em;
    margin-bottom: 1.6rem;
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    align-items: flex-start;

    span:nth-child(1) {
      width: 65%;
    }

    b {
      font-weight: bold;
    }

    strong {
      font-weight: bold;
      display: block;
    }
    small {
      display: block;
      font-size: 1.2rem;
      font-weight: bold;
    }

    &--total {
      margin-top: 0.8rem;
      padding-top: 0.8rem;

      span:nth-child(2) {
        font-size: 1.8rem;
      }
    }
  }

  &__delivery {
    &__progress {
      width: 100%;
      margin-top: -1.4rem;
      display: flex;
      align-items: center;
      gap: 1.6rem;
      font-size: 2.4rem;
      color: $color-success-500;

      &__bar {
        flex-grow: 1;
        font-size: 0;
        background-color: $color-grey-000;
        height: 0.8rem;
        display: flex;

        span {
          background-color: $color-success-500;
        }
      }
    }

    &__promo {
      width: 100%;
      margin-top: 1.6rem;

      [class*='mu-mt-050'] {
        display: flex;
      }

      [class*='mc-field__error-message'] {
        flex-grow: 1;
      }
    }
  }

  &__items {
    margin-bottom: 1.6rem;

    :global {
      // Override accordion Mozaic (exceptionel !)
      .mc-accordion {
        background-color: transparent !important;
        border: none !important;
        padding: 0 !important;

        &__content {
          padding: 0 !important;
        }

        &.is-open .mc-accordion__content {
          max-height: 35rem !important;
          overflow: auto !important;
        }

        &__trigger {
          padding-left: 0 !important;
        }

        &__title {
          font-size: 1.4rem !important;
          line-height: 1.2 !important;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;

      &__item {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        font-size: 1.3rem;

        &:not(:first-of-type) {
          margin-top: 0.8rem;
          padding-top: 0.8rem;
          border-top: 0.1rem solid $color-grey-200;
        }

        &__image {
          flex-shrink: 0;
          width: 5rem;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          align-self: flex-start;
        }

        &__details {
          flex-grow: 1;

          strong {
            display: block;
            margin-bottom: 0.25em;
          }

          span {
            color: $color-grey-500;
          }
        }

        &__price {
          flex-shrink: 0;
        }
      }
    }
  }
}

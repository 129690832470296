@import 'styles/variables/colors';
@import 'styles/mixins/full-width';
@import 'styles/mixins/media-queries';

.imagesTargets {
  margin-top: 8rem;
  margin-bottom: 8rem;

  &__title {
    font-weight: 700;
    font-size: 2.4rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.6rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  &__blocs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @include over-mobile {
      grid-template-columns: repeat(6, 1fr);
    }

    &__item {
      position: relative;
      overflow: hidden;
      font-size: 2rem;
    }

    @include over-mobile {
      &__item:first-child {
        grid-column-start: 1;
        grid-column-end: 4;
      }
      &__item:nth-child(2) {
        grid-column-start: 4;
        grid-column-end: 7;
      }
      &__item:nth-child(3),
      &__item:nth-child(4),
      &__item:nth-child(5) {
        grid-column: span 2;
      }
    }
  }

  @include on-tablet {
    &__blocs {
      &__item {
        font-size: 2.2rem;
      }
    }
  }

  @include on-desktop {
    &__blocs {
      &__item {
        font-size: 2.8rem;
      }
    }
  }
}

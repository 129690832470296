.ItemTilePictos {
  position: absolute;
  display: block;
  bottom: 0.8rem;
  font-size: 3rem;

  &[data-type='eresa'] {
    left: 3rem;
  }

  &[data-type='online'] {
    right: 3rem;
  }

  [small] & {
    font-size: 2.7rem;

    &[data-type='eresa'] {
      left: 1rem;
    }

    &[data-type='online'] {
      right: 1rem;
    }
  }
}

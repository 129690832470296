@import 'styles/variables/colors';
@import 'styles/mixins/full-width';
@import 'styles/mixins/media-queries';

.Targets {
  &__cible {
    position: absolute;
    cursor: pointer;
    filter: drop-shadow(0.5rem 0.5rem 0.75rem rgba($color-grey-999, 0.5));

    &__picto {
      width: 2em;
      border-radius: 50%;
      aspect-ratio: 1/1;
      object-fit: contain;
    }
  }
}

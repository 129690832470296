@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';
@import 'styles/mixins/typo';

.Fonctionnement {
  @extend .container;

  &__items {
    display: grid;
    grid-gap: 2.4rem;
    margin-top: 3.6rem;
    grid-template-columns: 1fr;
    justify-content: center;

    @include over-mobile {
      grid-template-columns: 1fr 1fr;
    }

    :nth-child(odd) {
      border-top-left-radius: 10rem;
    }

    :nth-child(even) {
      border-bottom-right-radius: 10rem;
    }

    &__text {
      padding: 10rem 2.4rem 6rem;
      background-image: url('./background.jpg');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      p {
        font-size: 2.4rem;
      }
    }

    &__loyalty {
      background: $color-grey-000;
      padding: 2.4rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      place-items: center;

      &__illustration {
        height: 19rem;
        width: 19rem;
        display: block;
        margin-bottom: 2.4rem;
      }

      &__title {
        flex-grow: 1;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.8rem;
        text-align: center;
        font-size: 3rem;
        font-weight: bold;

        span {
          @include housky;
          display: block;
          font-size: 8rem;
          font-weight: normal;
          color: $color-secondary-loyalty-primary;
        }

        em {
          display: block;
          font-style: normal;
          font-size: 1.8rem;
          font-weight: normal;
          margin-top: auto;
          padding: 2.4rem;
          border-top: 0.1rem solid $color-grey-100;
        }
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.Orderaddress {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;

  @include on-mobile {
    grid-template-columns: 1fr;
  }
  &__delivery,
  &__billing {
    &__title {
      font-size: 1.7rem;
      font-weight: 700;
      margin-bottom: 2rem;
    }
  }
}

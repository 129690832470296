@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.ContentTile {
  background-color: $color-grey-000;
  transition: background-color 0.2s linear;
  border: 0.1rem solid $color-grey-100;
  height: 100%;

  &:hover {
    background-color: $color-primary-01-100;
    border-color: $color-primary-01-200;
  }

  a {
    text-decoration: none;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    height: 100%;
    justify-content: space-between;
  }

  &__image {
    background-color: $color-grey-000;
    display: block;
    width: 100%;
    aspect-ratio: 10/8;
    object-fit: cover;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.4;
    height: 2rem * 2 * 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__description {
    font-weight: 300;
    height: 7rem;
  }

  &__link {
    font-weight: 700;
    text-decoration: underline;
    padding-top: 1.6rem;

    svg {
      margin-left: 0.5ch;
    }
  }

  &__infos {
    display: flex;
    justify-content: space-between;
    padding-top: 1.6rem;
    gap: 1.6rem;
    border-top: 0.1rem solid rgba($color-grey-999, 0.1);
    align-items: center;

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5ch;

      svg {
        font-size: 1.1em;

        &[data-active='false'] {
          opacity: 0.15;
        }
      }
    }
  }
}

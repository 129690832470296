@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';

.Banner {
  @include full-width(200rem);

  a {
    text-decoration: none;
  }

  &__picture {
    display: block;
    width: 100%;
  }

  &__button {
    svg {
      font-size: 0.8em;
    }

    &--desktop {
      display: none;
    }

    @include over-mobile {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
        position: absolute;
        width: 26rem;
        bottom: 7%;
        left: 4%;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.WorkshopInfos {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
  margin-left: 5rem;

  &__price {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 3.2rem;
    font-weight: bold;
  }

  &__title {
    font-size: 2.6rem;
    font-weight: bold;
    line-height: 3.6rem;
  }

  &__reviews {
    display: flex;
    gap: 1rem;
    font-size: 1.6rem;
    color: $color-grey-300;

    button {
      text-decoration: underline;
    }
  }
}

@include over-mobile {
  .ProductInfos {
    &__block {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: 2.6rem;
    }

    &__reviews {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}

@import 'styles/variables/colors';

.AnchorNav {
  &[data-is-fixed='true'] {
    position: fixed;
    top: 3rem;
    left: var(--offset-left, 0);
    min-width: var(--min-width, 0);
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      font-size: 1.6rem;
      display: block;
      width: 100%;

      button {
        text-decoration: none;
        text-align-last: left;
        padding: 1rem;
        display: block;
        width: 100%;
        background-color: transparent;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: $color-primary-01-200;
        }
      }
    }
  }
}

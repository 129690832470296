@import 'styles/variables/colors.scss';

.WorkshopTileImage {
  margin: auto;
  position: relative;
  background-color: $color-grey-000;
  width: 100%;
  aspect-ratio: 1/1;

  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

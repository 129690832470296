@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';

.TextTwoParagraph {
  @extend .container;
  margin-top: 8rem;

  &__title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    margin-bottom: 4rem;

    @include on-mobile {
      text-align: initial;
    }
  }

  &__text {
    display: flex;
    grid-gap: 8rem;
    margin-bottom: 8rem;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include on-mobile {
      flex-direction: column-reverse;
      gap: 3rem;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';

.RSE {
  @include full-width;
  border-top: 0.1rem solid $color-grey-100;

  &__container {
    @extend .container;
    position: relative;
  }

  &__logo {
    max-width: 35rem;
    margin: auto;
    padding: 3rem 2rem;
  }

  &__text {
    max-width: 42rem;
    margin: auto;
    margin-bottom: -10rem;
    padding: 1.6rem;
    background-color: $color-primary-01-200;
    position: relative;
    z-index: 1;

    li::before {
      content: '•';
      font-size: 0.75em;
      vertical-align: 10%;
      margin-left: 0.25em;
      margin-right: 0.75em;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    position: relative;
    z-index: 0;
  }

  @include on-tablet {
    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__logo {
      width: 35%;
      max-width: 30rem;
      flex-shrink: 0;
      margin-left: 0;
      padding: 0;
    }

    &__text {
      position: absolute;
      left: 35%;
      top: 50%;
      max-width: 40vw;
      transform: translate(0, -50%);
    }

    &__image {
      width: 65%;
      max-width: 64rem;
      flex-shrink: 0;
    }
  }

  @include on-desktop {
    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__logo {
      width: 32rem;
      padding: 0;
      margin-left: 3rem;
    }

    &__text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__image {
      width: 64rem;
    }
  }

  @include on-big-screen {
    &__logo {
      margin-left: 18rem;
    }
    &__text {
      transform: translate(-15%, -50%);
    }
  }
}

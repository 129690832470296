@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.OrderItem {
  &__head {
    display: grid;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    grid-template-columns: 53% 20% 20% 7%;
    border-bottom: 1px solid $color-grey-100;

    p {
      font-size: 1.5rem;
      font-weight: 300;
    }
    @include on-mobile {
      display: none;
    }
  }
  &__product {
    display: grid;
    margin-bottom: 3rem;
    grid-template-columns: 53% 20% 20% 7%;

    @include on-mobile {
      grid-template-columns: 70% 30%;
    }

    &__text {
      display: flex;
      justify-content: space-between;
      img {
        width: 13.5rem;
        height: 13.5rem;
        margin-right: 2rem;

        @include on-mobile {
          width: 8rem;
          height: 8rem;
        }
      }

      &__description {
        margin-right: 5rem;
        @include on-mobile {
          margin-right: 1rem;
        }
      }

      &__hidden {
        font-weight: bold;
        @include on-mobile {
          display: none;
        }
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';
@import 'styles/mixins/typo';

.Contact {
  @include full-width(200rem);
  position: relative;

  &__account {
    width: initial;
  }

  &__image {
    position: absolute;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  &__content {
    @extend .container;
    z-index: 2;
    padding-top: 5rem;
    padding-bottom: 5rem;
    color: $color-grey-000;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: bold;
  }

  &__subtitle {
    @include housky;
    font-size: 9rem;
  }

  &__blocks {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.4rem;
    max-width: 90rem;
    margin: auto;
  }

  &__block {
    background-color: $color-grey-000;
    color: $color-grey-800;
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__picto {
      font-size: 2.8em;
      margin-bottom: 1em;
    }

    p {
      color: $color-grey-500;
      font-size: 1.8rem;
    }

    &__cta {
      display: block;
      width: 100%;
      max-width: 33rem;
      margin: auto;
      text-decoration: none;
    }
  }

  @include over-mobile {
    &__title {
      font-size: 2.8rem;
    }

    &__subtitle {
      font-size: 10rem;
    }

    &__blocks {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@import 'styles/variables/colors.scss';

.PasswordStrengh {
  font-size: inherit;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  gap: 0.4rem;

  &__text {
    font-size: 1.2rem;
    line-height: 1;
    margin-right: 1ch;
    color: $color-grey-800;
  }

  &__pill {
    display: block;
    width: 1.6rem;
    height: 0.4rem;
    border-radius: 0.4rem;
    background-color: $color-grey-100;
  }

  $colors: (
    1: $color-danger-600,
    2: $color-warning-500,
    3: $color-success-400,
    4: $color-success-400,
  );

  @each $index, $color in $colors {
    &[data-strength='#{$index}'] {
      .PasswordStrengh__pill:nth-of-type(-n + #{$index + 1}) {
        background-color: $color;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';

.SeoBlock {
  max-width: 118rem;
  margin: 3.2rem auto;
  padding: 6rem 5rem;
  font-size: 1.6rem;
  font-weight: 100;
  background: $color-grey-100;

  h2,
  H3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.5em;
    font-weight: bold;

    &:not(:first-child) {
      margin-top: 0.7em;
    }
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.6rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  ul {
    li {
      margin-bottom: 0.4rem;
    }
  }

  @include on-desktop {
    padding-left: 9rem;
    padding-right: 9rem;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.Steps {
  &__check {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 4rem;
    @include on-mobile {
      flex-direction: column;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include on-mobile {
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;
      }

      p {
        text-align: center;
        max-width: 15rem;
        margin-top: 1.5rem;
        font-weight: 700;

        @include on-mobile {
          max-width: 100%;
          margin-top: 0;
        }
      }
    }

    &__notChecked {
      border: 1px solid $color-grey-100;
      border-radius: 50%;
      padding: 0.5rem;
      font-size: 3rem;
      color: $color-grey-000;
      background: $color-grey-100;
      @include on-mobile {
        margin-right: 2rem;
      }
    }
    &__checked {
      border: 1px solid $color-success-500;
      border-radius: 50%;
      padding: 0.5rem;
      font-size: 3rem;
      color: $color-grey-000;
      background: $color-success-500;
      @include on-mobile {
        margin-right: 2rem;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.Fonctionnement {
  ol {
    list-style: none;
    counter-reset: counter;

    li {
      counter-increment: counter;
      display: grid;

      &::before {
        content: counter(counter);
        background: black;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        display: inline-block;
        line-height: 4rem;
        color: white;
        text-align: center;
      }
      p {
        margin-left: 5.2rem;
        margin-top: -3.8rem;
      }
    }
  }

  h2 {
    font-size: 2.3rem;
    font-weight: 700;
    margin: 8rem 0 2.125rem;
  }

  &__items {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 3rem;

    & > div:nth-child(2) {
      grid-row: 1;
      border-top-right-radius: 7.2rem;

      @include on-mobile {
        grid-row: initial;
      }
    }

    & > div:nth-child(1) {
      grid-row: 1/3;

      @include on-mobile {
        grid-row: initial;
      }
    }

    & > div:nth-child(3) {
      background: white;
      border: 1px solid;
      grid-column: 2/3;

      @include on-mobile {
        grid-column: initial;
      }
    }

    @include on-mobile {
      grid-template-columns: 1fr;
    }
  }
}

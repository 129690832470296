@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.ProductDelivery {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &__method {
    display: flex;
    gap: 2rem;
    font-size: 1.4rem;

    &__cross {
      font-size: 2.2rem;
      width: 1em;
      height: 1em;
      position: relative;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 0.9em;
        height: 0.2em;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -0.1em;
        background-color: $color-danger-600;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    }

    &__picto {
      font-size: 2.2rem;
    }

    &__label {
      font-weight: bold;
      display: flex;
      gap: 0.5ch;
      flex-wrap: wrap;

      span {
        font-weight: normal;
        white-space: nowrap;
      }

      em {
        font-style: normal;
        white-space: nowrap;
        font-weight: normal;
        color: $color-grey-500;
      }
    }

    &__button {
      display: block;
      text-decoration: underline;
    }
  }
}

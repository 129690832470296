@import 'styles/variables/colors';
@import 'styles/mixins/typo';

.ProductBusinessSolutions {
  padding: 0.8rem 1.6rem;
  border: 0.1rem solid $color-grey-700;
  display: flex;
  align-items: center;
  gap: 1.6rem;

  &__logo {
    width: 13rem;

    img {
      width: 70%;
      margin-inline: auto;
    }

    span {
      @include housky;
      display: block;
      text-align: center;
      font-size: 3rem;
      line-height: 0.75em;
      color: $color-info-800;
    }
  }

  &__content {
    font-size: 1.6rem;
    line-height: 1.5em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;

    span {
      color: $color-info-800;
      font-weight: bold;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.WorkshopGiftcard {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  &__text {
    max-width: 78rem;
    margin: auto;
    font-style: 16rem;
  }

  &__content {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.4rem;
  }

  &__item {
    position: relative;
    width: 100%;
    max-width: 42rem;
    background-color: $color-primary-01-100;
    border: 1rem solid $color-grey-000;
    border-radius: 0.2rem;
    box-shadow: 0 0.2rem 0.4rem 0 rgba($color-grey-999, 0.1);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 2rem;
    margin-top: 10rem;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: $color-primary-01-200;
    }

    &__image {
      display: block;
      width: 90%;
      max-width: 30rem;
      aspect-ratio: 4/2.7;
      object-fit: cover;
      border-radius: 1.6rem;
      margin-top: -10rem;
      margin-bottom: 3rem;
      margin-left: auto;
      margin-right: auto;
    }

    &:nth-child(odd) &__image {
      transform: rotate(-15deg);
    }

    &:nth-child(even) &__image {
      transform: rotate(15deg);
    }

    &__title {
      font-size: 2.2rem;
    }

    &__subtitle {
      font-size: 1.8rem;
      font-weight: 100;
      width: 50%;
      margin: auto;
    }
  }

  @include on-tablet {
    &__item {
      margin-top: 7rem;

      &__image {
        margin-top: -7rem;
      }
    }
  }

  @include on-desktop {
    &__text {
      font-size: 1.8rem;
    }

    &__content {
      gap: 3.2rem;
    }

    &__item {
      max-width: 55rem;
      padding: 2rem 5rem;
      margin-top: 5rem;

      &__image {
        margin-top: -5rem;
        margin-bottom: 5rem;
      }
    }
  }
}

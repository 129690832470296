@import 'styles/variables/colors';

.ProductMoreLink {
  color: $color-grey-500;
  text-decoration: underline;
  margin-bottom: 2rem;

  &:hover {
    color: $color-grey-999;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.Fidelity {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3rem;
  margin: 3rem auto 4rem;

  h2 {
    margin: 0 0 3.5rem;
    font-size: 2.8rem;
    line-height: 1.45;
    font-weight: 400;
  }

  @include over-mobile {
    grid-template-columns: 25% 75%;
  }
  &__bloc {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-areas:
      'first second'
      'first third ';
    grid-gap: 2rem;
    @include on-mobile {
      grid-template-columns: 100%;
      grid-template-areas:
        'first'
        'second'
        'third';
    }

    & > div:nth-child(1) {
      grid-area: first;
      margin-bottom: 3rem;

      @include on-mobile {
        grid-row: initial;
      }
    }

    & > div:nth-child(2) {
      grid-area: second;
      margin-top: 1.6rem;

      @include on-mobile {
        grid-row: initial;
      }
    }

    & > div:nth-child(3) {
      grid-area: third;
      margin-bottom: 1.6rem;

      @include on-mobile {
        grid-row: initial;
      }
    }
    &__item {
      border: 1px solid $color-grey-100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 3.5rem 4rem 1.5rem;
      h3 {
        font-size: 2.5rem;
        font-weight: 500;
      }
      p {
        @include housky;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 9rem;
        line-height: 1em;
        text-align: center;
      }
      div {
        width: 100%;
        margin-top: 2rem;
      }
      &__subtitle {
        font-size: 1.5rem;
        line-height: 1.875rem;
        font-weight: 300;
        text-align: center;
      }
    }
  }
  &__title {
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;

    h3 {
      text-align: center;
      font-size: 3rem;

      p {
        @include housky;
        margin-bottom: 1rem;
        font-size: 9rem;
        line-height: 1em;
        text-align: center;
      }
    }
    p {
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 2.875rem;
      margin: 0 auto 5.625rem;
      max-width: 910px;
      text-align: center;
    }
  }
  &__recompense {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;

    @include on-mobile {
      grid-template-columns: 1fr;
    }
  }
}

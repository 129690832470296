@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';

.WorkshopLoyalty {
  @include full-width();
  position: relative;
  overflow: hidden;
  background-color: $color-primary-01-100;

  &::before,
  &::after {
    content: '';
    position: absolute;
    aspect-ratio: 1/1;
    height: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0;
  }

  &::before {
    background-image: url('./images/shape-beige.png');
    transform: translate(-70%, 0);
    left: 0;
    bottom: 0;
  }

  &::after {
    background-image: url('./images/shape-grey.png');
    transform: translate(95%, 0%) scale(1.2);
    right: 0;
    top: 0;
  }

  &__content {
    @extend .container;
    min-height: 20rem;
    padding: 2rem;
    text-align: center;
    z-index: 1;

    &__text {
      max-width: 70rem;
      margin-left: auto;
      margin-right: auto;
    }

    &__cards {
      margin-top: 5rem;
      margin-bottom: 3rem;
      display: flex;
      gap: 2.4rem;
      align-items: center;
      justify-content: center;
    }
  }

  @include on-tablet {
    &::before {
      transform: translate(-60%, -20%);
    }

    &::after {
      transform: translate(85%, -20%) scale(1.2);
    }
  }

  @include on-desktop {
    &::before {
      transform: translate(-50%, -30%) scale(1.2);
    }

    &::after {
      transform: translate(70%, -15%) scale(1.5);
    }

    &__content {
      &__cards {
        gap: 3.2rem;
      }
    }
  }

  @include on-big-screen {
    &::before {
      left: 50%;
      transform: translate(-120rem, -30%) scale(1.5);
    }

    &::after {
      left: 50%;
      transform: translate(70rem, -10%) scale(1.75);
    }
  }
}

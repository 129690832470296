@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';

.Title {
  text-align: center;
  max-width: 73rem;
  margin: 5rem auto;

  h1 {
    text-align: center;
    font-size: 4.4rem;
    font-weight: 700;
    margin-bottom: 3rem;

    @include on-mobile {
      font-size: 2.4rem;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 3rem;

    @include on-mobile {
      font-size: 1.6rem;
    }
  }

  &__description {
    padding-top: 4rem;
    text-align: center;
    font-weight: 300;

    p {
      font-size: 1.8rem;
      line-height: 1.875;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.ProductTabReviews {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  &__title {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__content {
    font-size: 1.6rem;
    font-weight: 300;
  }

  &__summary {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    li {
      display: flex;
      gap: 3rem;
    }

    &__percentage {
      color: $color-grey-700;
    }

    &__count {
      margin-left: auto;
      font-weight: normal;
    }
  }

  &__list {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    li {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }

    &__name {
      font-weight: bold;
    }

    &__date {
      font-size: 1.2rem;
      color: $color-grey-500;
      margin-left: auto;
    }
  }

  &__pagination {
    display: flex;
    margin-top: 3rem;
    padding: 2rem;
    border-top: 0.1rem solid $color-grey-100;
    order: 3;
    width: 100%;
  }

  @include over-mobile {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0;

    &__column {
      &:nth-of-type(1) {
        width: 30rem;
        flex-shrink: 0;
        order: 2;
      }

      &:nth-of-type(2) {
        width: calc(100% - 30rem - min(5vw, 10rem));
        order: 1;
      }
    }

    &__list {
      gap: 3.2rem;

      li {
        display: flex;
        flex-direction: row;
        gap: 3rem;

        div {
          &:nth-of-type(1) {
            width: 14rem;
            flex-shrink: 0;
          }

          &:nth-of-type(2) {
            flex-grow: 1;
          }
        }
      }

      &__name {
        font-weight: bold;
      }

      &__date {
        text-align: right;
        font-size: 1.2rem;
        color: $color-grey-500;
      }
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.ProductsTargets {
  text-decoration: none;
  position: absolute;
  top: 110%;
  left: -20%;
  width: 16em;
  min-width: 16rem;
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  line-height: 1.1;
  font-size: 0.6em;
  margin-left: var(--offsetLeft, 0);
  margin-top: var(--offsetTop, 0);
  border-bottom-right-radius: 30%;
  background: $color-primary-01-100;
  z-index: 1;
  transition: opacity 0.2s linear;

  &[data-visible='false'] {
    pointer-events: none;
    opacity: 0;
  }

  &[data-visible='true'] {
    pointer-events: all;
    opacity: 1;
  }
}

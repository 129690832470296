@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.LoyaltyAccount {
  h4 {
    text-align: center;
    font-weight: 400;
    font-size: 2.1rem;
    margin-top: 2rem;
  }

  &__housky {
    @include housky;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 7rem;
    line-height: 1em;
    text-align: center;
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.35rem;
    text-align: center;
  }
  label {
    font-size: 1.7rem;
    color: black;
    margin-bottom: 1rem;
    margin-top: 3rem;
    display: block;
  }
  &__button {
    margin-top: 4rem;
  }
  ul {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 3rem 5rem;
    background: #f2f2f2;
    margin-bottom: 4rem;

    @include on-mobile {
      flex-direction: column;
    }
    @include on-tablet {
      flex-direction: column;
    }

    li {
      padding-left: 5rem;
      position: relative;
      display: flex;
      flex-direction: column;
      counter-increment: item;

      &::before {
        content: counter(item);
        font-size: 1.8rem;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        width: 4rem;
        height: 4rem;
        border-radius: 100%;
        background-color: $color-grey-999;
        color: $color-grey-000;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__loyalty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__housky {
      @include housky;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 7rem;
      line-height: 1em;
      text-align: center;
    }
    &__points {
      font-size: 5rem;
      color: $color-secondary-ss-orange;
      font-weight: 700;
    }
    &__text {
      font-size: 2.4rem;
      font-weight: 500;
    }
    &__programme {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
      hr {
        margin-bottom: 2rem;
      }
      p {
        font-size: 2rem;
        line-height: 4rem;
        font-weight: 500;
      }
    }
  }
}

@import '/styles/variables/colors';
@import '/styles/mixins/full-width';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';

.StoreMap {
  &__title {
    text-align: center;
    font-size: 7rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    @include housky;
    @include picto('quote', 'after');

    @include on-tablet {
      font-size: 9rem;
    }
    @include on-desktop {
      font-size: 11rem;
    }

    &::after {
      font-size: 0.3em;
      transform: rotate(180deg);
      vertical-align: bottom;
      margin-left: -0.2em;
    }

    span {
      display: block;
      text-indent: -3rem;
      @include picto('quote', 'before');

      &::before {
        font-size: 0.3em;
        vertical-align: top;
        margin-top: -0.75em;
      }
    }
  }

  &__map {
    @include full-width(200rem);
    height: 65rem;
    max-height: 95vh;
    margin-bottom: 5rem;
    background-color: #d3d3d3;
    display: flex;
    z-index: 0;
    position: relative;

    &__loader {
      color: $color-grey-800;
      font-size: 7rem;
    }
  }
}

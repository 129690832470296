@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.BlocOrders {
  padding: 6rem 3rem 1.25rem;
  text-align: center;
  background: #f1e7d8;
  h2 {
    font-size: 2.4rem;
    font-weight: 700;
    width: 60%;
    margin: 0 auto 3rem;
    text-align: center;
  }
  &__text {
    font-size: 1.9rem;
    font-weight: 400;
    text-align: center;
    margin: 0 0 2rem;
  }
  &__housky {
    @include housky;
    margin-top: 6rem;
    margin-bottom: 4rem;
    font-size: 8.5rem;
    line-height: 1em;
    text-align: center;
  }
  &__head {
    display: flex;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    gap: 1rem;

    p {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
  &__body {
    display: grid;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    grid-template-columns: repeat(4, 25%);
    justify-content: space-between;
    border-bottom: 1px solid black;
    gap: 1rem;
    font-size: 1.5rem;
  }

  &__button a {
    display: flex;
  }
}

@import 'styles/variables/colors';

.Encart {
  &__modalite {
    padding: 3rem;
    background: $color-primary-01-100;

    h3 {
      font-weight: 700;
      font-size: 2.25rem;
      text-align: center;
      margin: 0 0 2rem;
    }

    h4 {
      font-weight: 700;
      font-size: 2rem;
      margin: 0 0 2.125rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.875;
      font-weight: 300;
    }
  }

  &__retour {
    background: $color-secondary-aw-pink;
    padding: 3rem;
    text-align: center;
    color: #fff;

    h3 {
      font-size: 2rem;
      margin: 0 0 0.5rem;
      font-weight: 700;
    }

    p {
      font-size: 2rem;
      margin: 0;
      font-weight: 300;
      line-height: 1.825;
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.Paragraph {
  margin: 5rem auto;

  &__title {
    margin-bottom: 4.5rem;
    font-size: 2rem;
    font-weight: 700;
  }

  &__block {
    display: flex;
    gap: 3rem;
    flex-direction: column;

    & + & {
      margin-top: 3rem;
    }

    &__text {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      font-weight: 300;
      line-height: 1.875;
    }

    &__image {
      width: 100%;

      img {
        display: block;
        width: 100%;
        aspect-ratio: 3.6/2;
        object-fit: cover;
      }
    }
  }

  @include over-mobile {
    max-width: 104rem;
    width: calc(100% - 4rem);
    margin: 0;

    &__block {
      &--left {
        flex-direction: row-reverse;
        grid-template-columns: 30% 70%;
        grid-auto-flow: dense;
      }

      &--right {
        flex-direction: row;
        grid-template-columns: 70% 30%;
      }

      &__image {
        width: 30%;
        flex-shrink: 0;
      }
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.StoreInfos {
  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
    border-bottom: 0.1rem solid $color-grey-100;

    &__title {
      font-size: 2.6rem;
      font-weight: bold;
      flex-grow: 1;
    }

    &__button {
      width: 100%;
      max-width: 32rem;
      margin-inline: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    font-size: 1.8rem;
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
    border-bottom: 0.1rem solid $color-grey-100;
  }

  &__block {
    width: 100%;
    text-align: center;

    &__title {
      font-size: 2.2rem;
      font-weight: bold;
      margin-bottom: 3.2rem;
    }

    &__line {
      display: flex;
      justify-content: center;
      gap: 1.6rem;

      svg {
        font-size: 1.25em;
      }

      a {
        text-decoration: none;
      }
    }
  }

  @include on-desktop {
    &__top {
      flex-direction: row;
    }

    &__content {
      flex-direction: row;
    }

    &__block {
      text-align: left;

      &__line {
        justify-content: flex-start;
      }
    }
  }
}

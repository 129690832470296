@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.WorkshopIcons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  &__bloc {
    display: flex;
    flex-direction: column;
    width: 20%;
    text-align: center;
    &__icon {
      width: 70px;
      height: 70px;
      margin: auto;
      border-radius: 50%;
      background: #f8f3ec;
      position: relative;
      padding-top: 1.5rem;

      &__picto {
        font-size: 3.6rem;
      }
    }
    &__name {
      font-size: 1.4rem;
      margin-top: 0.8rem;
      text-transform: lowercase;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.projet {
  margin: 7rem auto;

  h2 {
    margin-bottom: 2.5rem;
    font-size: 2.875rem;
    font-weight: 700;
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    font-weight: 300;
    line-height: 1.875;
  }

  &__button {
    text-align: center;
    max-width: 33rem;
  }

  &__imageRight {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 3rem;

    @include on-mobile {
      grid-template-columns: 1fr;
    }
  }
  &__imageLeft {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 3rem;

    @include on-mobile {
      grid-template-columns: 1fr;
    }

    .projetParagrah {
      order: 2;
    }
  }
  .projetImage {
    order: 1;

    img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 5/3;
      display: block;
      height: 100%;
      display: block;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/typo';

.About {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  position: relative;

  &__title {
    font-size: 1.6rem;
    font-weight: bold;
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2.1em;
    font-weight: 100;
    max-width: 80%;
  }

  &__baseline {
    @include housky;
    font-size: 28vw;
    text-align: center;
    margin-top: 5rem;
  }

  &__image {
    display: block;
    margin-top: -5.5rem;
    margin-left: -2rem;
    margin-right: -2rem;
    position: relative;
    z-index: -1;

    img {
      width: 100%;
      aspect-ratio: 5/2.6;
      object-fit: cover;
    }

    span {
      display: none;
    }
  }
}

@include over-mobile {
  .About {
    padding-top: 14rem;
    padding-bottom: min(15rem, 20vw);
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    &__title {
      width: 100%;
      font-size: 2.4rem;
    }

    &__text {
      max-width: 40%;
    }

    &__baseline {
      width: 100%;
      text-align: left;
      font-size: min(12vw, 16rem);
      margin-left: 1rem;
    }

    &__image {
      margin: 0;
      width: 45%;
      position: absolute;
      right: -2rem;
      top: 17rem;

      img {
        width: 100%;
        aspect-ratio: 1.07/1;
        object-fit: cover;
      }

      span {
        display: block;
        position: absolute;
        width: 70%;
        top: 50%;
        text-align: center;
        left: 0;
        transform: translate(-25%, -50%);
        font-size: 2rem;
        line-height: 1.5em;
        padding: 4rem 2.5rem;
        font-weight: 100;
        background-color: $color-primary-01-200;
      }
    }
  }
}

@include on-desktop {
  .About {
    padding-left: 2.5rem;
    padding-bottom: min(15rem, 10vw);
    gap: 4rem;

    &__text {
      font-size: 2.4rem;
      line-height: 1.4em;
      max-width: 38%;
    }

    &__image {
      width: 42%;
      position: absolute;
      top: 8rem;
      right: 0;

      span {
        font-size: 3.6rem;
        padding: 3rem 4rem;
        width: 67%;
        transform: translate(-32%, -50%);
      }
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';

.BannerNotFull {
  max-width: 144rem;
  margin: 7rem auto;
  position: relative;

  &__picture {
    img {
      display: block;
      width: 100%;
      margin-bottom: 6rem;
    }
  }
}

@import 'styles/variables/colors.scss';

.RangeSliderFilter {
  display: block;
  height: 4rem;
  margin-top: 4rem;
  position: relative;

  &__track {
    position: absolute;
    inset: 0 2rem;
    display: block;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      inset: 1.9rem 0;
      background-color: $color-grey-999;
    }
  }

  &__slider {
    position: relative;
    display: block;
    width: 4rem;
    height: 4rem;
    margin-left: -2rem;
    cursor: grab;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      inset: 1.2rem;
      border-radius: 100%;
      background-color: $color-grey-999;
    }
  }

  &__valueLabel {
    white-space: nowrap;
    display: block;
    position: absolute;
    top: 0;
    transform: translate(-50%, -75%);
    padding: 0.125rem 0.25rem;
    background-color: $color-grey-000;
  }

  &__minLabel,
  &__maxLabel {
    display: none;
  }
}

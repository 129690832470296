@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.dashboard {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-gap: 3rem;
  margin: 3rem auto auto;

  @include on-mobile {
    grid-template-columns: 100%;
  }
  @include on-tablet {
    grid-template-columns: 100%;
  }

  &__personal {
    margin: 0 auto;
    @include on-mobile {
      margin: initial;
    }
    &__header {
      background: url('dashboard-header.webp');
      display: grid;
      grid-template-columns: 2fr 1fr;
      padding-top: 7rem;
      padding-bottom: 15rem;
      padding-right: 4rem;
      @include on-mobile {
        grid-template-columns: 1fr;
        padding-left: 3rem;
        padding-right: 3rem;
        button {
          margin-top: 4rem;
        }
      }
      @include on-tablet {
        grid-template-columns: 1fr;
        padding-left: 3rem;
        padding-right: 3rem;
        button {
          margin-top: 4rem;
        }
      }
      &__infos {
        color: $color-grey-000;
        margin-left: 3rem;
        h2 {
          font-size: 2.8rem;
          font-weight: 300;
          margin-bottom: 1rem;
        }

        ul {
          display: flex;
          font-size: 1.7rem;
          font-weight: 300;
          li:not(:first-child) {
            margin-left: 1rem;
          }
          li {
            display: flex;
            align-items: center;
            span {
              display: block;
              margin-left: 0.8rem;
            }
          }
          @include on-mobile {
            flex-direction: column;
          }
        }
      }
    }
    &__blocs {
      display: grid;
      grid-template-columns: 45% 45%;
      grid-gap: 2rem;
      justify-content: center;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      @include on-mobile {
        grid-template-columns: 1fr;
        margin-left: 3rem;
        margin-right: 3rem;
      }
      @include on-tablet {
        grid-template-columns: 1fr;
      }

      & > div:nth-child(1) {
        grid-row: 1/3;
        border-top-left-radius: 7.2rem;
        margin-top: -12rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include on-mobile {
          grid-row: initial;
        }
        @include on-tablet {
          grid-row: initial;
        }
      }

      & > div:nth-child(2) {
        grid-row: 1;
        margin-top: -12rem;
        @include on-mobile {
          grid-row: initial;
          margin-top: initial;
        }
        @include on-tablet {
          grid-row: initial;
          margin-top: initial;
        }
      }
      & > div:nth-child(3) {
        grid-column: 2/3;
        @include on-mobile {
          grid-column: initial;
        }
        @include on-tablet {
          grid-column: initial;
        }
      }
    }
    &__fidelity {
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }
}

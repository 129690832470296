@import 'styles/mixins/media-queries';
@import 'styles/common/container';
@import 'styles/mixins/typo';
@import 'styles/mixins/full-width';

.CollectePoints {
  @include full-width;

  &__container {
    @extend .container;
    max-width: 118rem;
  }

  &__title {
    font-size: 2.2rem;
    max-width: 30rem;
    margin: 4rem auto;
    text-align: center;

    span {
      display: block;
      font-weight: 700;
      margin-left: 0.5rem;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
    grid-gap: 2rem;
  }

  &__link {
    display: block;
    text-align: center;
    max-width: 33rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    text-decoration: none;
  }
}

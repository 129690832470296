.Faq {
  &__title {
    margin-bottom: 4.5rem;
    text-align: center;
    font-size: 2.875rem;
    font-weight: 700;
  }

  &__questions {
    max-width: 100rem;
    margin: 0 auto;

    &__title {
      font-weight: 700;
      font-size: 2.625rem;
      margin-bottom: 3rem;
    }
  }
}

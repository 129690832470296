.blogForm {
  label {
    font-size: 1.6rem;
    color: black;
  }
  &__button {
    text-align: center;
    max-width: 32rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
  }

  &__file {
    display: flex;
    margin-top: 3rem;
    align-items: center;
    &__icon {
      border: 1px solid #7f7f7f;
      border-radius: 50%;
      padding: 1rem;
      height: 8rem;
      width: 8rem;
      display: block;
      margin-right: 2rem;

      &__blog {
        font-size: 5rem;
        color: #7f7f7f;
        padding-left: 1rem;
      }
    }
  }
  &__social {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: space-between;
    &__icon {
      border: 1px solid black;
      border-radius: 50%;
      padding-top: 0.7rem;
      height: 4rem;
      width: 4rem;
      display: block;
      margin-bottom: 2rem;
      text-align: center;

      &__active {
        background: #000;
        svg {
          color: white;
        }
      }

      &__blog {
        font-size: 2rem;
        color: black;
      }
    }
  }
}

.formInfos {
  text-align: left;
  div {
    text-align: left;
  }

  &__gender-form {
    margin-top: 1rem;
    div:first-of-type {
      display: flex;
      flex-direction: column;
    }
  }
  label {
    font-size: 1.6rem;
    color: black;
  }

  &__button {
    text-align: center;
    max-width: 32rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.StoreEvents {
  padding-block: 4.8rem;

  &__title {
    text-align: center;
    font-size: 2.6rem;
    font-weight: bold;
    margin-bottom: 3.2rem;

    span {
      @include housky;
      @include picto('quote', 'before');
      @include picto('quote', 'after');
      font-size: 3em;
      margin-top: 0.15em;
      display: block;
      position: relative;
      width: max-content;
      margin-inline: auto;

      &::before,
      &::after {
        position: absolute;
        font-size: 0.3em;
      }

      &::before {
        left: -1em;
        top: 0;
      }

      &::after {
        right: -1em;
        bottom: 0;
        transform: rotate(180deg);
      }
    }
  }
}

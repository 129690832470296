@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';
@import 'styles/fonts/Blacker/Blacker';

.WorkshopsBanner {
  margin-top: 4.8rem;
  background-color: $color-primary-01-100;

  &__text {
    padding: 3.2rem 2rem;

    &__title {
      font-family: Blacker, serif;
      font-size: 2.8rem;
      margin-bottom: 0.25em;
      line-height: 1.1;
      font-weight: bold;
    }

    p {
      font-size: 1.4rem;
      font-weight: 100;
    }
  }

  &__image {
    display: none;
  }

  @include over-mobile {
    display: flex;
    align-items: center;
    border-top-left-radius: 7rem;
    border-bottom-right-radius: 7rem;
    overflow: hidden;

    &__text {
      flex-grow: 1;
    }

    &__image {
      display: block;
      flex-shrink: 0;
      width: 40%;
      aspect-ratio: 2/1;
      object-fit: cover;
    }
  }

  @include on-tablet {
    gap: 2.4rem;

    &__text {
      padding: 3.2rem;
    }
  }

  @include on-desktop {
    gap: 4.8rem;

    &__text {
      padding: 3.2rem 4.8rem;

      &__title {
        font-size: 4.8rem;
      }

      p {
        font-size: 1.8rem;
      }
    }
  }
}

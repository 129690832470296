@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';

.BannerInspiHouse {
  max-width: 144rem;
  margin: auto;
  margin-bottom: 10rem;
  margin-top: 10rem;
  position: relative;

  &__picture {
    img {
      display: block;
      width: 100%;
      margin-bottom: 6rem;
    }
  }
  &__bloc {
    position: absolute;
    top: 8%;
    left: 5%;
    bottom: 20%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6rem;
    border-bottom-right-radius: 30%;
    background: white;

    @include on-mobile {
      right: 10%;
      left: 10%;
      padding: 4rem;
      top: 50%;
      bottom: 15%;
    }

    &__text {
      h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 48px;
        @include on-mobile {
          font-size: 24px;
          line-height: 32px;
        }
      }
      p {
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
        margin-top: 2rem;
        @include on-mobile {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  &__action {
    text-align: center;
    display: flex;
    justify-content: center;
    @include over-mobile {
      a {
        max-width: 33rem;
      }
    }
  }
}

.WorkshopTileDetails {
  margin-inline: auto;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  &__item {
    display: flex;
    gap: 1rem;
    font-weight: bold;

    svg {
      font-size: 1.25em;
    }

    em {
      display: block;
      font-weight: normal;
      font-size: 0.9em;
    }
  }
}

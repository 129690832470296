.SocialFlow {
  text-align: center;

  &__title {
    font-size: 2.4rem;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 2.2rem;
    font-weight: 100;
    margin-bottom: 4.2rem;
  }
}

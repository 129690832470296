@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/hide-scrollbar';
@import 'styles/mixins/picto';

$thumbnailSize: min(9rem, 18vw);
$arrowWidth: 5rem;

.MediasGallery {
  @include hide-scrollbar;
  width: 100%;
  margin: -0.4rem;
  padding: 0.4rem;

  &__video {
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-fullscreen='false'] {
      background-color: $color-grey-999;
    }

    &[data-fullscreen='true'] {
      background-color: $color-grey-000;
    }

    iframe {
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: contain;
    }

    &__thumbnail {
      display: block;
      position: relative;

      &__image {
        position: relative;
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        opacity: 0.7;
        z-index: -1;
      }

      &__picto {
        padding: 0.75rem;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba($color-grey-999, 0.5);
        color: $color-grey-000;
        font-size: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 0;
        }
      }
    }
  }

  // STYLE GLOBAL GALLERY
  :global {
    .image-gallery {
      position: relative;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      background-color: $color-grey-000;
      font-size: 0;

      &.fullscreen-modal {
        position: fixed;
        inset: 0;
        z-index: 2;

        .image-gallery-thumbnails-wrapper {
          display: none;
        }

        .image-gallery-slide-wrapper {
          width: 100vh;
          height: 100vh;
          margin: auto;
        }
      }

      &-content {
        position: relative;
        display: flex;
        overflow: hidden;

        &.top,
        &.bottom {
          flex-direction: column;
          gap: 0.8rem;
        }

        &.left,
        &.right {
          flex-direction: row;
        }
      }

      // DEFAULT SVG ICONS
      &-icon {
        display: none;
      }

      // THUMBNAILS
      &-thumbnails {
        overflow: auto;
        padding: 0.4rem;
        margin: -0.4rem;

        &-wrapper {
          position: relative;
          flex-shrink: 0;

          &.top,
          &.bottom {
            padding: 0.4rem;

            .image-gallery-thumbnails-container {
              flex-direction: row;
            }

            &::after {
              content: '';
              position: absolute;
              width: 3rem;
              right: 0;
              top: 0;
              bottom: 0;
              background: linear-gradient(90deg, transparent, $color-grey-000);
            }
          }

          &.left,
          &.right {
            width: calc($thumbnailSize + 0.8rem);
            padding: 0.4rem;

            .image-gallery-thumbnails-container {
              flex-direction: column;
            }

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 3rem;
              background: linear-gradient(transparent, $color-grey-000);
              pointer-events: none;
            }
          }

          &.thumbnails-swipe-horizontal {
            touch-action: pan-y;
          }

          &.thumbnails-swipe-vertical {
            touch-action: pan-x;
          }

          &.thumbnails-wrapper-rtl {
            direction: rtl;
          }
        }

        &-container {
          display: flex;
          gap: 0.8rem;
        }
      }

      &-thumbnail {
        flex-shrink: 0;

        &.active {
          outline: 0.2rem solid $color-grey-999;
          outline-offset: -0.2rem;
        }

        &-image {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: contain;
        }
      }

      // MEDIAS
      &-swipe {
        display: flex;
        width: 100%;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
      }

      &-slides {
        width: 100%;
        margin: auto;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        cursor: pointer;
      }

      &-slide {
        position: absolute;
        inset: 0;

        &.center {
          position: relative;
        }

        &-wrapper {
          position: relative;
          display: inline-block;
          height: 100%;
          margin-left: auto;
          margin-right: auto;

          &.top,
          &.bottom {
            overflow: hidden;
            width: calc(100% - 0.8rem);
            margin-left: 0.4rem;
            margin-right: 0.4rem;
          }

          &.left,
          &.right {
            width: calc(100% - calc($thumbnailSize + $arrowWidth + 0.8rem));
            margin-top: 0.4rem;
          }

          &.image-gallery-rtl {
            direction: rtl;
          }
        }
      }

      &-image {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
      }

      // BUTTON CLOSE
      button[data-action='close'] {
        display: flex;
        font-size: 2.4rem;
        line-height: 0;
        padding: 0.75em;
        position: fixed;
        top: 1rem;
        right: 1rem;
        color: $color-grey-500;
        z-index: 4;

        &[disabled] {
          display: none;
        }
      }

      // BUTTON ARROWS
      button[data-action='prev'],
      button[data-action='next'] {
        display: flex;
        position: absolute;
        font-size: $arrowWidth - 0.8rem;
        top: 50%;
        margin-top: -0.5em;
        z-index: 3;
        opacity: 0;
        transition: all 0.2s linear;
        pointer-events: none;

        // Hide arrow on touch devices tablet and mobile
        @include under-desktop {
          @media (hover: none) {
            display: none;
          }
        }

        &::before {
          content: '';
          position: absolute;
          left: -0.4rem;
          right: -0.4rem;
          top: -50vh;
          bottom: -50vh;
          opacity: 0.5;
          background-color: $color-grey-000;
          z-index: -1;
        }
      }

      &-slide-wrapper {
        &:hover {
          button[data-action='prev'],
          button[data-action='next'] {
            &:not([disabled]) {
              opacity: 1;
              pointer-events: all;
            }
          }
        }

        &.top,
        &.bottom {
          button[data-action='prev'] {
            left: -0.5em;
          }

          button[data-action='next'] {
            right: -0.5em;
          }

          &:hover {
            button[data-action='prev'] {
              left: 0;
            }

            button[data-action='next'] {
              right: 0;
            }
          }
        }

        &.left,
        &.right {
          button[data-action='prev'] {
            left: -1em;
          }

          button[data-action='next'] {
            right: -1em;
          }

          &:hover {
            button[data-action='prev'] {
              left: -0.5em;
            }

            button[data-action='next'] {
              right: -0.5em;
            }
          }
        }
      }

      &.fullscreen-modal .image-gallery-slide-wrapper,
      &.fullscreen-modal .image-gallery-slide-wrapper:hover {
        &.top,
        &.bottom,
        &.left,
        &.right {
          button[data-action='prev'],
          button[data-action='next'] {
            position: fixed;

            &:not([disabled]) {
              opacity: 1;
              pointer-events: all;
            }
          }

          button[data-action='prev'] {
            left: 0.4rem;
          }

          button[data-action='next'] {
            right: 0.4rem;
          }
        }
      }
    }
  }
}

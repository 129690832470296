@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';

.ProductStickyBanner {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $color-grey-100;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 1;

  &__content {
    display: flex;
    @extend .container;
    align-items: center;
    gap: 3.2rem;
  }

  &__image {
    width: 7rem;
    aspect-ratio: 1/1;
    object-fit: contain;
  }

  &__title {
    font-size: 2rem;
    font-weight: bold;
    flex-grow: 1;
  }

  &__price {
    flex-shrink: 0;
  }

  &__button {
    flex-shrink: 0;

    svg {
      font-size: 1.25em;
      margin-left: 1rem;
    }
  }
}

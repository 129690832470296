@import 'styles/variables/colors';
@import 'styles/common/container';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/mixins/typo';

.HeaderCheckout {
  @include full-width(200rem);
  border-bottom: 0.1rem solid $color-grey-100;
  margin-bottom: 3.2rem;

  &__container {
    @extend .container;
    display: flex;
    gap: 1.6rem;
    padding-block: 1.6rem;
    align-items: center;
    flex-wrap: wrap;
  }

  &__button {
    max-width: 22rem;
    flex-grow: 1;
  }

  &__logo {
    display: flex;
    text-decoration: none;
    align-items: center;
    margin-inline: auto;

    img {
      width: min(15rem, 30vw);
    }

    span {
      display: none;
    }
  }

  @include on-desktop {
    &__logo {
      transform: translateX(-11rem);
    }
  }

  @include over-mobile {
    &__logo {
      img {
        width: 12.5rem;
      }

      span {
        display: block;
        @include housky;
        font-size: 3.5rem;
        padding-top: 0.6rem;
        padding-left: 3rem;
        margin-left: 1.5rem;
        margin-top: 1.5rem;
        text-indent: -1.5rem;
        border-left: 0.25rem solid $color-grey-999;
        line-height: 0.45;
      }
    }
  }
}

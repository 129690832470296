@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';
@import 'styles/mixins/typo';

.Avantages {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;

  &__items {
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 2.4rem;

    @include over-mobile {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

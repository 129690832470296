@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.Positionnement {
  &__title {
    margin-bottom: 4.5rem;
    margin-top: 6rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }

  table {
    border-spacing: 2.3rem;
    border-collapse: separate;

    th {
      font-size: 1.9rem;
      text-align: left;

      p {
        padding-bottom: 0;
      }
    }
  }

  &__block {
    display: flex;
    gap: 3rem;
    flex-direction: column;

    & + & {
      margin-top: 3rem;
    }

    &__image {
      width: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    &__text {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      font-weight: 300;
      line-height: 1.875;
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.TutoSteps {
  padding-top: 4.8rem;
  padding-bottom: 3.2rem;

  &__title {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__list {
    margin-top: 2.4rem;

    &__item {
      padding: 1.6rem;
      margin-inline: -2rem;

      & + & {
        border-top: 0.1rem solid $color-grey-100;
      }

      &__title {
        font-size: 1.8rem;
        margin-bottom: 0.75em;
        font-weight: bold;
        text-transform: uppercase;
      }
      &__bloc {
        display: grid;
        gap: 2rem;
        align-items: center;
        grid-template-columns: 25% auto;

        img {
          aspect-ratio: 10/6;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }

  @include on-tablet {
    &__list {
      &__item {
        padding: 2.4rem;

        &__title {
          font-size: 2rem;
        }
      }
    }
  }

  @include on-desktop {
    max-width: 118rem;
    margin-inline: auto;

    &__list {
      &__item {
        padding: 3.2rem;
        margin-inline: auto;

        &__title {
          font-size: 2.2rem;
        }
      }
    }
  }
}

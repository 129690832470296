@import 'styles/mixins/typo';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/picto';

.TutoTips {
  max-width: 118rem;
  margin: 4.8rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;

  &__chief {
    display: block;
    width: 18rem;
    max-width: 50%;
    @include picto('quote', 'before');
    flex-shrink: 0;

    &::before {
      position: absolute;
      top: -0.8em;
      left: -0.8em;
      font-size: 3.2rem;
    }

    img {
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }

  &__tip {
    text-align: center;

    h2 {
      @include housky;
      font-size: 8rem;
      margin-block: 1.6rem;
    }

    p {
      line-height: 1.2;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 1.6rem;
    width: 100%;
    max-width: 38rem;
  }

  @include over-mobile {
    flex-direction: row;
    gap: 1.6rem;

    &__chief {
      width: 16rem;
    }

    &__tip {
      text-align: left;
      margin-right: 3.2rem;
      max-width: 42rem;
    }

    &__buttons {
      max-width: 32rem;
      margin-left: auto;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.TutoHeader {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__content {
    &__title {
      font-size: 2.4rem;
      color: $color-grey-999;
      font-weight: bold;
      margin-block: 1.6rem;
    }

    &__subtitle {
      padding-block-end: 1.6rem;
      font-size: 1.6rem;
    }
  }

  &__button {
    &__picto {
      font-size: 2.4rem;
    }
  }

  @include on-desktop {
    &__content {
      &__title {
        font-size: 3.2rem;
      }
    }
  }
}

@import 'styles/mixins/media-queries';

.WorkshopTabs {
  padding-top: 5rem;
  padding-bottom: 5rem;

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
  }

  &__content {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }

  @include on-desktop {
    &__title {
      font-size: 2.4rem;
    }
  }
}

@import 'styles/mixins/media-queries';

.WorkshopsListHeader {
  text-align: center;

  &__title {
    font-size: 1.6em;
    font-weight: bold;
  }

  &__button {
    text-decoration: underline;
    margin-top: 0.5em;
  }

  @include over-mobile {
    &__title {
      font-size: 2.2em;
      font-weight: bold;
    }

    &__button {
      font-size: 1.8rem;
    }
  }
}

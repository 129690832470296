@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.LoyaltyCard {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  padding: 1rem;
  background-image: url('./images/background-mobile.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__fidelity {
    flex-direction: column;

    &__content {
      width: 100%;
    }
  }

  &__text {
    flex-shrink: 0;
    width: 25rem;
    max-width: 80%;
    margin: auto;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    &__baseline {
      width: 100%;
      aspect-ratio: 1/1;
      font-size: 0;
      background-image: url('./images/entre-nous.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &__userId {
      text-align: center;
      font-size: 2rem;
      line-height: 4rem;
      font-weight: 500;

      span {
        display: block;
      }
    }
  }

  &__content {
    width: 100%;
    background-color: $color-grey-000;
    padding: 2rem;
  }

  @include over-mobile {
    height: 100%;
    padding: 3.2rem 1.6rem;
    background-image: url('./images/background-desktop.jpg');
    flex-direction: row;
    &__fidelity {
      flex-direction: column;
    }

    &__content {
      width: 55%;
    }

    &__contentFidelity {
      width: 100%;
    }

    &__text {
      width: 20rem;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';

.StoreExperts {
  @include full-width;
  background-color: $color-primary-01-200;

  &__content {
    @extend .container;
    padding-block: 3.2rem;

    &__title {
      font-size: 2.6rem;
      font-weight: bold;
      margin-bottom: 3.2rem;
    }

    &__list {
      padding-block: 1.6rem;
      display: flex;
      gap: 3.2rem;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      margin-inline: -2rem;

      &__item {
        scroll-snap-align: start center;
        flex-shrink: 0;
        width: 22rem;
      }
    }
  }

  &__member {
    text-align: center;

    &__image {
      width: 80%;
      margin-inline: auto;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 100%;
    }

    &__name {
      font-size: 2.2rem;
      font-weight: bold;
      margin-top: 1.6rem;
      margin-bottom: 0.8rem;
    }

    &__details {
      font-size: 1.6rem;
      line-height: 1.2;
    }
  }

  @include on-desktop {
    &__content {
      &__title {
        text-align: center;
      }

      &__list {
        max-width: 85rem;
        margin-inline: auto;
        justify-content: center;
        flex-wrap: wrap;

        &__item {
          width: 26rem;
        }
      }
    }
  }
}

@import 'styles/variables/colors';

.TutoMaterials {
  margin-block: 3.2rem;

  &__content {
    &__title {
      font-size: 1.6rem;
      font-weight: bold;
      margin-top: 1.6rem;
      margin-bottom: 0.8rem;
    }

    &__list {
      list-style-type: disc;
      margin-left: 3.2rem;

      &__item {
        color: $color-grey-999;
        font-weight: bold;
        margin-bottom: 0.8rem;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';
@import 'styles/mixins/typo';

.Points {
  @include full-width;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url('./collect_bg_d.webp');
  background-size: min(100%, 200rem);
  background-position: center center;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

@import 'styles/mixins/media-queries';

.InfosLegalesSecurite {
  &__title {
    font-size: 2.8rem;
    margin-bottom: 3.5rem;
    font-weight: bold;
  }

  &__content {
    margin-bottom: 5rem;

    h2 {
      font-size: 2.4rem;
      line-height: normal;
      margin: 3.5rem 0 3rem;
    }

    h3 {
      font-size: 2.125rem;
      line-height: 1.8rem;
      font-weight: 700;
      margin: 5rem 0 3.5rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 3rem;
      font-weight: 300;
    }

    ul li {
      margin-bottom: 2rem;
      margin-left: 4rem;
      list-style-type: circle;
    }
  }
}

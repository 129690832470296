@import 'styles/variables/colors';
@import 'styles/mixins/full-width';
@import 'styles/mixins/media-queries';

.ImagesTargetWithText {
  margin-top: 8rem;
  margin-bottom: 8rem;

  &__title {
    font-weight: 700;
    font-size: 2.4rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.6rem;
    margin-bottom: 5rem;
    text-align: center;
    max-width: 73rem;
    margin-right: auto;
    margin-left: auto;
  }

  &__bloc {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;

    @include on-mobile {
      grid-template-columns: 1fr;
    }

    &__first {
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;

      &__description {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #f8f3ec;
        border-bottom-left-radius: 16rem;
        padding: 6.4rem 12.8rem 6.4rem 16rem;
        @include on-mobile {
          border-bottom-left-radius: initial;
          padding: 3rem;
        }
      }
    }
    &__second {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @include on-mobile {
        flex-direction: column-reverse;
      }

      &__description {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #f8f3ec;
        border-top-right-radius: 16rem;
        padding: 4.4rem 12.8rem 6.4rem 16rem;
        @include on-mobile {
          border-top-right-radius: initial;
          padding: 3rem;
        }
      }
    }

    &__item {
      position: relative;
      overflow: hidden;
      font-size: 2rem;

      img {
        max-width: 71rem;
      }
    }
  }
}

@import 'styles/variables/colors.scss';

.ItemTileVariants {
  width: auto;
  max-width: 50%;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.2em;

  [small] & {
    width: 100%;
    max-width: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 0.25rem;
    width: 100%;

    &__item {
      margin-top: 0.5rem;

      &[data-type='color'] {
        margin-right: 0.25rem;
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;
        border: 0.2rem solid $color-grey-000;
        box-shadow: 0 0 0 0.1rem $color-grey-200;
      }

      &[data-type='value'] {
        padding: 0.35em;
        border: 0.1rem solid currentColor;
      }
    }
  }

  &__choices {
    width: 100%;
    margin-top: 1rem;
    font-weight: bold;
    text-align: right;
    text-decoration: underline;

    [small] & {
      font-size: 1.2rem;
      margin-top: 0.25rem;
    }
  }
}

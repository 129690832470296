@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';
.Navigation {
  &__title {
    margin-bottom: 3rem;
    padding: 0 2.5rem;
    padding-left: 0;

    @include on-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h1 {
      font-weight: 700;
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    &__logout {
      color: #7f7f7f;
      font-size: 1.3rem;
      text-decoration: underline;
      display: block;
      margin-bottom: 2.125rem;
    }
  }

  &__links {
    &__active {
      background: #f8f3ec;
      color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
      @include on-tablet {
        background: none;
        a {
          border-bottom: 1px solid;
        }
      }
      @include on-mobile {
        background: none;
        a {
          border-bottom: 1px solid;
        }
      }

      a {
        font-weight: 700;
      }
    }
    ul {
      display: initial;
      overflow: hidden;

      @include on-mobile {
        display: flex;
        overflow: scroll;
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 6rem;
          height: 110%;
          right: 0;
          top: 0;
          position: absolute;
          z-index: 2;
          background: #fff;
          background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(75%, #fff));
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #fff 75%);
        }
      }
      @include on-tablet {
        display: flex;
        overflow: scroll;
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 6rem;
          height: 110%;
          right: 0;
          top: 0;
          position: absolute;
          z-index: 2;
          background: #fff;
          background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(75%, #fff));
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #fff 75%);
        }
      }

      li {
        a {
          display: flex;
          padding: 1.2rem 2.5rem;
          font-size: 1.6rem;
          transition: 0.3s;
          text-decoration: none;
          position: relative;
          align-items: center;
          @include on-tablet {
            padding-right: 0;
            padding-left: 0;
            margin-left: 1rem;
            margin-right: 1rem;
            width: 180px;
          }
          @include on-mobile {
            padding-right: 0;
            width: 180px;
            padding-left: 0;
            margin-left: 1rem;
            margin-right: 1rem;
          }

          span {
            margin-left: 0.9rem;
          }

          &:hover {
            background: #f8f3ec;
            color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.Filters {
  position: relative;

  &__button {
    position: relative;

    small {
      margin-left: 1ch;
      background-color: $color-grey-999;
      color: $color-grey-000;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
      line-height: 1;
      border-radius: 1em;
      font-weight: normal;
      padding: 0.25em;
      min-width: 1.5em;
      white-space: nowrap;
      font-variant-numeric: tabular-num;
    }
  }

  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 55rem;
    max-width: 95vw;
    background-color: $color-grey-000;
    border-left: 0.1rem solid $color-grey-200;
    overflow: auto;
    padding: 1.6rem 1.6rem 9rem 1.6rem;
    transition: transform 0.2s ease;
    z-index: 2;

    &__close {
      position: fixed;
      right: 0;
      bottom: 0;
      width: 55rem;
      max-width: 95vw;
      background-color: $color-grey-000;
      border-left: 0.1rem solid $color-grey-200;
      padding: 0 2rem 2rem 2rem;
      box-shadow: 2rem -2.5rem 2.5rem 0 $color-grey-000;
      transition: transform 0.2s ease;
      z-index: 3;
    }

    &__overlay {
      position: fixed;
      inset: 0;
      background-color: rgba($color-grey-999, 0.35);
      transition: opacity 0.2s ease;
      z-index: 1;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    overflow: auto;
  }

  &__item {
    width: 100%;
    padding: 2.4rem;
    background-color: $color-grey-000;
    border: 0.1rem solid $color-grey-100;

    &__title {
      font-size: 1.6rem;
      display: block;
      line-height: 1;
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid rgba($color-grey-999, 0.1);
    }

    &--alt {
      background-color: $color-primary-01-100;
      border-color: $color-primary-01-100;
    }
  }

  &__empty {
    padding: 3.2rem 1.6rem;
    background: $color-grey-100;
    font-weight: bold;
    text-align: center;
  }

  @include under-desktop {
    &__container[data-open='false'] {
      transform: translateX(100%);

      & ~ .Filters__container__close {
        transform: translateX(100%);
      }

      & ~ .Filters__container__overlay {
        opacity: 0;
        pointer-events: none;
      }
    }

    &__container[data-open='true'] {
      transform: translateX(0);

      & ~ .Filters__container__close {
        transform: translateX(0);
      }

      & ~ .Filters__container__overlay {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  @include on-desktop {
    &__button {
      display: none;
    }

    &__container {
      position: static;
      width: auto;
      padding: 0;
      max-width: none;
      border-left: none;

      &__close,
      &__overlay {
        display: none;
      }
    }

    &__items {
      gap: 2.4rem;
    }
  }
}

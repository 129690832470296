@import '/styles/variables/colors';
@import '/styles/mixins/media-queries';
@import '/styles/mixins/full-width';
@import '/styles/common/container';

.StoreList {
  @include full-width;
  margin-top: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $color-primary-01-100;

  &__items {
    @extend .container;
    columns: 28rem;
    gap: 2rem;

    li {
      padding-bottom: 2rem;

      a {
        text-decoration: none;
      }
    }
  }
}

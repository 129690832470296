@import 'styles/variables/colors';
@import 'styles/mixins/hide-scrollbar';

.Slider {
  --swiper-theme-color: #{$color-grey-999};
  --swiper-pagination-bullet-size: 1rem;
  --swiper-navigation-size: 1.6rem;

  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 0;

  &__swiper {
    position: relative;

    &[data-arrows='true'] {
      max-width: calc(100% - 4.8rem);
      margin: auto;
    }

    &__item {
      display: block;

      &__inner {
        padding: 0.4rem;
        margin: auto;
        height: 100%;
      }
    }
  }

  :global {
    .swiper {
      overflow: visible;
      position: static;

      &-pagination {
        @include hide-scrollbar;
        position: relative;
        height: 4rem;
        margin-top: 2rem;
      }

      &-button-prev,
      &-button-next {
        align-items: center;
        justify-content: center;
        width: 2.8rem;
        height: 2.8rem;
        margin-top: -1.4rem;
        left: auto;
        right: auto;
        z-index: 1;

        &::after {
          font-size: 1.8rem;
        }

        &:not(.swiper-button-lock) {
          top: var(--arrow-offset-top, calc(50% - 3rem));
        }

        &.swiper-button-disabled {
          opacity: 0.3;
        }
      }

      &-button-prev {
        left: 0;
      }

      &-button-next {
        right: 0;
      }
    }
  }
}

@import 'styles/mixins/full-width';
@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.BannerCatalog {
  @include full-width(220rem);
  position: relative;

  &__image {
    img {
      display: block;
      aspect-ratio: 7/5;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    padding: 25vw 1.6rem 1.6rem 1.6rem;

    &[data-background='color-ss-pink'] {
      background-color: $color-secondary-ss-pink;
      color: $color-grey-999;
    }
    &[data-background='color-ss-orange'] {
      background-color: $color-secondary-ss-orange;
      color: $color-grey-999;
    }
    &[data-background='color-ss-blue'] {
      background-color: $color-secondary-ss-blue;
      color: $color-grey-999;
    }
    &[data-background='color-ss-bluedark'] {
      background-color: $color-secondary-ss-bluedark;
      color: $color-grey-999;
    }
    &[data-background='color-aw-pink'] {
      background-color: $color-secondary-aw-pink;
      color: $color-grey-000;
    }
    &[data-background='color-aw-green'] {
      background-color: $color-secondary-aw-green;
      color: $color-grey-000;
    }
    &[data-background='color-aw-green--dark'] {
      background-color: $color-secondary-aw-greendark;
      color: $color-grey-000;
    }
    &[data-background='color-aw-blue--dark'] {
      background-color: $color-secondary-aw-bluedark;
      color: $color-grey-000;
    }

    &__text {
      max-width: 35rem;
      margin-inline: auto;

      h2 {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 1.325em;
        margin-bottom: 1em;
      }

      p {
        font-weight: 100;
      }
    }

    &__button {
      display: block;
      width: 100%;
      max-width: 35rem;
      margin-inline: auto;
      margin-top: 2rem;
      text-align: center;
      font-size: 1.3rem;
      font-weight: bold;
      padding: 1.2em 2.8em;
      border: 0.1rem solid $color-grey-000;
      background-color: $color-grey-000;
      color: $color-grey-999;
      text-decoration: none;

      &:nth-of-type(2) {
        background: transparent;
        color: $color-grey-000;
      }
    }
  }

  &__visual {
    position: absolute;
    width: 50vw;
    left: 50%;
    top: 30vw;
    transform: translate(-50%, 0);
  }

  @include on_desktop {
    display: flex;
    flex-direction: row;
    aspect-ratio: auto;

    &__image {
      width: 50%;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        aspect-ratio: auto;
      }
    }

    &__content {
      width: 50%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 5rem;
      padding-left: min(25vw, 22rem);

      &__text {
        margin-left: 0;

        h2 {
          font-size: 3.2rem;
        }
      }

      &__button {
        margin-left: 0;
      }
    }

    &__visual {
      width: 50vw;
      max-width: 32rem;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.passwordForm {
  div {
    text-align: left;
  }

  label {
    font-size: 1.6rem;
    color: black;
  }

  &__button {
    text-align: center;
    max-width: 32rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.Confirmation {
  text-align: center;
  margin-block: 5rem;

  &__title {
    font-size: 3.4rem;
    margin-bottom: 1em;
  }

  &__details {
    font-weight: 100;
    margin-block: 3.2rem;

    &__title {
      font-style: 2.2rem;
      margin-bottom: 2em;
    }

    &__list {
      max-width: 58rem;
      margin-inline: auto;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      &__item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.6rem;

        &:not(:first-of-type) {
          padding-top: 1.6rem;
          border-top: 0.1rem solid $color-grey-100;
        }

        &__image {
          width: 7rem;
        }

        &__name {
          text-align: left;
          font-weight: inherit;
          width: calc(100% - 7rem - 1.6rem);
        }

        &__quantity {
          width: auto;
          margin-left: 7rem + 1.6rem;
        }

        &__price {
          margin-left: auto;
        }
      }
    }
  }

  &__summary {
    max-width: 70rem;
    margin-inline: auto;
    background-color: $color-grey-100;
    border: 0.1rem solid $color-grey-200;
    padding: 1.6rem 3.2rem;
    font-weight: 100;

    &__line {
      display: flex;
      justify-content: space-between;
    }
  }

  @include over-mobile {
    &__title {
      font-size: 3.2rem;
    }

    &__details {
      &__title {
        font-style: 1.8rem;
      }

      &__list {
        &__item {
          flex-wrap: nowrap;

          &__name {
            width: auto;
            flex-grow: 1;
          }

          &__quantity,
          &__price {
            width: 8rem;
            margin-left: 0;
          }
        }
      }
    }

    &__summary {
      padding: 3.2rem;
    }
  }
}

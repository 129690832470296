@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/variables/colors';

.BrandContent {
  color: $color-grey-999;

  &__rich {
    p {
      text-align: center;
      font-size: 2rem;
      font-weight: 400;
      max-width: 114.5rem;
      margin: 8rem auto 10rem;
      line-height: 3rem;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
    place-items: center;
    margin-bottom: 8rem;

    @include on-mobile {
      grid-template-columns: repeat(1, 1fr);
      flex-direction: column-reverse;
    }

    &__text {
      text-align: center;
      h2 {
        margin-bottom: 4rem;
      }
      p {
        font-size: 1.7rem;
        line-height: 5rem;
        margin-bottom: 3.5rem;
      }
    }

    img {
      aspect-ratio: 1/1;
      object-fit: cover;
      @include on-mobile {
        grid-row: 1;
      }
    }
  }
}

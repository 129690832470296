@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.Form {
  counter-reset: step;

  &__step {
    counter-increment: step;

    &:not(:first-of-type) {
      margin-top: 4.8rem;
    }

    &__title {
      font-size: 2.4rem;
      line-height: 1.1;
      margin-bottom: 1.5em;
      font-weight: bold;
      display: flex;
      align-items: center;

      &::before {
        content: counter(step);
        flex-shrink: 0;
        font-size: 1.25em;
        display: inline-flex;
        margin-right: 1ch;
        height: 1.8em;
        width: 1.8em;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: $color-primary-01-200;
      }
    }

    &__fields {
      display: flex;
      align-items: flex-end;
      gap: 2.4rem;
      flex-wrap: wrap;

      &__price {
        width: 100%;
        display: flex;
        align-items: flex-end;
        gap: 2.4rem;
        flex-wrap: wrap;
      }

      &__priceButton {
        width: calc((100% / 3) - (2.4rem * 2 / 3));
      }

      &__priceInput {
        width: 100%;
        margin-top: -1.6rem;
      }

      &__quantitySelector {
        width: 100%;
      }

      &__visual {
        width: 100%;
        padding: 1.6rem 2.4rem 2.4rem;
        border-radius: 1.6rem;
        border: 0.1rem solid $color-grey-100;
        text-align: center;
        font-weight: bold;
        cursor: pointer;

        img {
          margin-top: 1.6rem;
          width: 100%;
          aspect-ratio: 85/54;
          object-fit: cover;
          border-radius: 1em;
        }

        &__upload {
          margin-top: 1.6rem;
          width: 100%;
          aspect-ratio: 2/1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &[data-active='true'] {
          border-color: $color-secondary-loyalty-primary;
          background-color: $color-primary-01-200;
        }
      }

      &__submit {
        width: 100%;
        margin-top: 1.6rem;
      }
    }
  }

  &__modal {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;

    &__preview {
      flex-grow: 1;
      max-width: 20rem;
      border-radius: 1rem;
      margin: auto;
      aspect-ratio: 85/54;
      object-fit: cover;
      background-color: $color-grey-100;
    }

    &__details {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      &__name {
        font-size: 1.8rem;
        font-weight: bold;
      }

      &__quantity {
        font-size: 1.4rem;
        font-weight: 100;
      }
    }

    &__price {
      margin-left: auto;
      font-size: 1.8rem;
      font-weight: bold;
    }
  }

  @include over-mobile {
    &__step {
      &__fields {
        &__priceButton {
          width: 15%;
        }

        &__priceInput {
          width: auto;
          flex-grow: 1;
        }

        &__quantitySelector {
          max-width: 24rem;
        }

        &__visual {
          width: calc(50% - 1.2rem);
        }

        &__submit {
          max-width: 34rem;
        }
      }
    }

    &__modal {
      gap: 3.2rem;
      align-items: center;

      &__preview {
        margin: 0;
        width: 12rem;
        border-radius: 0.6rem;
      }

      &__details {
        width: auto;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.ChoisirZodio {
  margin: 5rem auto;

  &__title {
    margin-bottom: 4.5rem;
    text-align: center;
    font-size: 2.875rem;
    font-weight: 700;
  }

  &__block {
    display: flex;
    gap: 3rem;
    flex-direction: column;

    & + & {
      margin-top: 3rem;
    }

    &__text {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      font-weight: 300;

      h3 {
        font-size: 1.2em;
      }

      p {
        line-height: 2em;
      }
    }

    &__image {
      width: 100%;

      img {
        display: block;
        width: 100%;
        aspect-ratio: 3.6/2;
        object-fit: cover;
      }
    }
  }

  @include over-mobile {
    &__block {
      &--left {
        flex-direction: row-reverse;
        grid-template-columns: 30% 70%;
        grid-auto-flow: dense;
      }

      &--right {
        flex-direction: row;
        grid-template-columns: 70% 30%;
      }

      &__image {
        width: 30%;
        flex-shrink: 0;
      }
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';
@import 'styles/variables/colors';

.VignettesText {
  @extend .container;
  border: 1px solid $color-grey-100;
  padding: 4rem;
  padding-left: 15rem;
  padding-right: 15rem;
  @include on-mobile {
    padding: 2rem;
  }

  &__content {
    display: flex;
    grid-gap: 9rem;
    margin-top: 5rem;
    @include on-mobile {
      grid-gap: 1rem;
    }

    img {
      width: initial;
    }

    &__text {
      h3 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 3.2rem;
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 2rem;
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    @include on-mobile {
      flex-direction: column;
    }
  }
}

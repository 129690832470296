@import 'styles/variables/colors.scss';

.ItemTileImages {
  margin: auto;
  position: relative;
  background-color: $color-grey-000;
  width: 100%;
  aspect-ratio: 1/1;

  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;

    &:nth-child(2) {
      display: none;

      article a:hover &,
      article a:focus & {
        display: block;
      }
    }

    &:nth-child(n + 3) {
      display: none;
    }
  }
}

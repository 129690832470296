@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';
@import 'styles/mixins/full-width';
@import 'styles/common/container';

.WorkshopsSelectStore {
  @include full-width;
  margin-block: 3.2rem;
  background-color: $color-primary-01-100;

  &__inner {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 3.2rem;
    gap: 2.4rem;

    &__title {
      font-weight: bold;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      gap: 1.6rem;

      svg {
        font-size: 3.2rem;
      }
    }

    &__button {
      width: 100%;
      max-width: 45rem;
    }
  }

  @include over-mobile {
    &__inner {
      &__title {
        font-size: 2.8rem;
      }

      &__button {
        max-width: 37rem;
      }
    }
  }
}

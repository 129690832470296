@import 'styles/mixins/media-queries';

.Faq {
  max-width: 60rem;
  margin: 5rem auto;

  &__questions {
    &__title {
      font-weight: 700;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 3rem;
    }
  }
}

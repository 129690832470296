@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.WorkshopTabFormer {
  text-align: center;

  &__image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.6rem;
    margin-bottom: 3.2rem;
  }

  &__descriptif {
    font-size: 2rem;
    font-weight: 400;
  }
  &__actions {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: 3rem;

    font-size: 1.25em;
    margin-right: 1rem;

    @include on-mobile {
      flex-direction: column;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/fonts/Blacker/Blacker';

.WorkshopBanner {
  @include full-width(200rem);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 3/2;
  background-color: $color-grey-000;

  a {
    text-decoration: none;
  }

  &__picture {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
  }

  &__content {
    margin-top: -1.5em;
    font-size: 4vw;
    line-height: 1;
    display: flex;
    justify-content: center;
    user-select: none;
    z-index: 1;
    filter: drop-shadow(0 0 0.2em $color-grey-000, 0.9);

    &__picto {
      display: block;
      width: 5em;
      aspect-ratio: 1/1;
      object-fit: contain;
      object-position: center;
    }

    &__title {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 0.25em;
      font-family: Blacker, serif;
      white-space: nowrap;
      font-weight: bold;
      font-size: 2em;
      line-height: 0.75;

      span {
        font-size: 0.75em;
        line-height: 0.6;
      }
    }
  }

  @include on-tablet {
    aspect-ratio: 5/2;

    &__content {
      font-size: 2.5rem;
    }
  }

  @include on-desktop {
    aspect-ratio: 3.62/1;

    &__content {
      font-size: min(4rem, 3vw);
    }
  }
}

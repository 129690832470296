@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/typo';

.FideliteHeader {
  padding: 2rem;
  max-width: 82rem;
  margin: auto;
  text-align: center;

  &__title {
    font-size: 2.8rem;
    line-height: 1.1;

    em {
      @include housky;
      display: block;
      margin-top: 2.4rem;
      font-size: 8rem;
    }
  }

  &__subtitle {
    font-size: 1.6rem;
    margin: 2.4rem auto 5rem;
  }

  @include over-mobile {
    &__title {
      font-size: 3.6rem;

      em {
        font-size: 11rem;
      }
    }
  }
}

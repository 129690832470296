@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/typo';

.ProductUGC {
  max-width: 118rem;
  margin-inline: auto;
  padding-block: 4.8rem;

  &__content {
    margin-bottom: 2.4rem;

    &__list {
      user-select: none;

      &__item {
        display: block;
        width: 100%;
        aspect-ratio: 1/1;
        position: relative;
        background-color: $color-grey-100;
        font-size: 1.6rem;

        &__mask {
          position: absolute;
          inset: 0;
          background-color: rgba($color-grey-500, 0.5);
          opacity: 0;
          transition: opacity 0.2s ease;
          display: flex;
          flex-direction: column;
          gap: 1ch;
          justify-content: center;
          align-items: center;
          padding: 1.6rem;
          color: $color-grey-000;
          text-align: center;
          font-size: inherit;

          img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
          }
        }

        &:focus,
        &:hover {
          .ProductUGC__content__list__item__mask {
            opacity: 1;
          }
        }

        &[data-type='image'] {
          font-size: 3.2rem;
        }
      }
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.EnseigneDeux {
  &__title {
    margin-bottom: 4.5rem;
    font-size: 2rem;
    font-weight: 700;
  }

  &__block {
    display: flex;
    gap: 3rem;
    flex-direction: column-reverse;

    & + & {
      margin-top: 3rem;
    }

    &__text {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      font-weight: 300;
    }

    &__image {
      width: 100%;
      flex-shrink: 0;

      img {
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  @include over-mobile {
    &__block {
      flex-direction: row-reverse;

      &__image {
        width: 30%;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.mentions {
  font-size: 1.3rem;
  margin-top: 5rem;
  font-weight: 300;

  h2 {
    font-size: 2.3rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';

.FooterUnivers {
  @include full-width();
  display: flex;
  justify-content: space-between;
  padding: 3.4rem;
  background: $color-primary-01-200;

  &__item {
    width: 30%;
    max-width: 27rem;
    flex-shrink: 0;

    &:last-child {
      max-width: 29rem;
    }

    &__picto {
      display: block;
      width: 3.1rem;
      margin-bottom: 0.6rem;
      aspect-ratio: 1/1;
      object-fit: contain;
    }

    &__text {
      display: block;
      font-size: 1.4rem;
      line-height: 1.25em;
    }
  }
}

@include on-tablet {
  .FooterUnivers {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    justify-content: center;

    &__item {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      display: flex;
      align-items: center;

      &__picto {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }
}

@include on-desktop {
  .FooterUnivers {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    justify-content: center;

    &__item {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      display: flex;
      align-items: flex-start;

      &__picto {
        width: 4rem;
        flex-basis: 4rem;
        margin-top: 0.8rem;
        margin-bottom: 0;
        margin-right: 2rem;
      }

      &__text {
        font-size: 2.2rem;
      }
    }
  }
}

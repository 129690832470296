@import 'styles/common/container';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/variables/colors';

.ParagraphTwoImgs {
  text-align: center;
  margin: 5rem auto;
  @extend .container;

  &__title {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.4rem;
    max-width: 73rem;
    margin-bottom: 3rem;
    margin-right: auto;
    margin-left: auto;
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    margin-top: 4rem;

    @include on-mobile {
      margin-top: 2rem;
      gap: 1rem;
    }

    &__subtitle {
      font-weight: 300;
      font-size: 1.6rem;
      line-height: 2.4rem;
      max-width: 73rem;
      margin-bottom: 3rem;
      margin-right: auto;
      margin-left: auto;
    }

    &__images {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
      margin-top: 4rem;

      @include on-mobile {
        margin-top: 2rem;
        gap: 1rem;
      }

      img {
        aspect-ratio: 8/7;
        object-fit: cover;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';
@import 'styles/mixins/full-width';

.MarqueUne {
  @include full-width();
  background: $color-primary-01-100;

  &__container {
    @extend .container;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    align-items: center;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  &__image {
    display: block;
    max-width: 25rem;
  }

  &__button {
    width: 100%;
    max-width: 33rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    height: 5.6rem;
  }
}

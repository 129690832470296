@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/typo';

.Reward {
  background: $color-primary-01-200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;

  &__text {
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.4rem;
    display: block;
    line-height: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  div {
    width: 100%;
    margin-top: 0.5rem;
  }

  &__cart {
    border: 1px solid #000;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    text-align: left;
    padding: 0.6rem;
    font-size: 1.1rem;
    margin: 0;
    margin-left: -8rem;
  }

  &__fillName {
    width: 7rem;
    margin-top: 5rem;
    margin-bottom: 1rem;
  }
  &__actions {
    background: $color-grey-000;
    padding: 1rem;
    font-size: 1.3rem;

    &__icon {
      border: 1px solid $color-success-500;
      border-radius: 50%;
      padding: 0.5rem;
      font-size: 3rem;
      color: $color-grey-000;
      background: $color-success-500;
    }
    &__dispo {
      border: 1px solid $color-grey-100;
      padding: 0.3rem;
      margin-bottom: 1rem;
      margin-top: 3rem;
      text-align: center;
      p {
        background: $color-success-500;
        color: $color-grey-000;
        padding: 0.6rem;
      }
    }
  }

  &__modal {
    &__head {
      font-size: 1.6rem;
      color: $color-grey-900;
    }
    &__title {
      font-size: 2rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
    }
    &__conditions {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      flex-wrap: nowrap;
      gap: 2rem;

      &__panel {
        border: 1px solid $color-grey-200;
        padding: 1.25rem 1rem;
        max-width: calc(50% - 0.625rem);
        text-align: center;
        font-size: 1.2rem;

        h4 {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
        svg {
          font-size: 2.6rem;
        }
      }
    }
  }
}

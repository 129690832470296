@import 'styles/mixins/media-queries';

.WorkshopTabInfos {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  &__title {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__content {
    font-size: 1.6rem;
    font-weight: 300;

    ul {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      strong {
        font-weight: normal;
      }
    }
  }

  @include over-mobile {
    flex-direction: row;

    &__column {
      width: 50%;
    }
  }
}
@include over-mobile {
  .WorkshopTabInfos {
    flex-direction: row;
    &__column {
      width: 50%;
    }
  }
}

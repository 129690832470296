@import 'styles/mixins/media-queries';

.FootNotes {
  font-weight: 100;
  margin-block: 4.8rem;

  &[data-size='regular'] [class*='RichText'] {
    font-size: 1.4rem;
  }

  &[data-size='small'] [class*='RichText'] {
    font-size: 1.1rem;
  }

  @include over-mobile {
    &[data-size='regular'] [class*='RichText'] {
      font-size: 1.8rem;
    }

    &[data-size='small'] [class*='RichText'] {
      font-size: 1.2rem;
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/mixins/typo';
@import 'styles/variables/colors';

.Comment {
  padding-top: 5rem;
  padding-bottom: 5rem;

  &__items {
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: 1fr;
    max-width: 50rem;
    padding-top: 5rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__item {
    position: relative;
    margin-top: -5rem;

    &__index {
      position: absolute;
      top: 5rem;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.8rem;
      line-height: 1.8em;
      width: 1.8em;
      height: 1.8em;
      text-align: center;
      border-radius: 100%;
      color: $color-grey-000;
      background-color: $color-grey-999;
      z-index: 2;
    }

    &__inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      background-color: $color-primary-01-200;
      clip-path: polygon(
        /* 1 */ 0 0,
        /* 2 */ 50% 5rem,
        /* 3 */ 100% 0,
        /* 4 */ 100% calc(100% - 5rem),
        /* 5 */ 50% 100%,
        /* 6 */ 0 calc(100% - 5rem)
      );
      padding: 8rem 0.5rem 5rem 0.5rem;
    }

    &__title {
      text-align: center;
      font-size: 2rem;
      justify-content: center;

      span {
        @include housky;
        display: block;
        font-size: 8rem;
        line-height: 1;
      }

      em {
        display: block;
        font-size: 1.4rem;
        color: $color-grey-500;
        min-height: 4.2rem;
        font-style: normal;
      }
    }

    &__illustration {
      display: block;
      height: 8rem;
      aspect-ratio: 1/1;
      object-fit: contain;
    }
  }

  &__link {
    max-width: 33rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
  }

  @include on-desktop {
    &__title {
      font-size: 10rem;
    }

    &__items {
      grid-template-columns: repeat(3, 1fr);
      width: 118rem;
      max-width: 80vw;
      transform: translateX(5rem);
    }

    &__item {
      position: relative;
      margin-top: 0;
      margin-left: -5rem;

      &__index {
        top: 0rem;
      }

      &__inner {
        clip-path: polygon(
          /* 1 */ 0 0,
          /* 2 */ calc(100% - 5rem) 0,
          /* 3 */ 100% 50%,
          /* 4 */ calc(100% - 5rem) 100%,
          /* 5 */ 0 100%,
          /* 6 */ 5rem 50%
        );
        padding: 4rem 5rem 4rem 8rem;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/full-width';
@import 'styles/common/container';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.StoreServices {
  @include full-width;
  background-color: $color-grey-100;
  margin-bottom: 7rem;

  &__content {
    padding-block: 4.8rem;
    @extend .container;

    &__title {
      text-align: center;
      font-size: 2.6rem;
      font-weight: bold;
      margin-bottom: 3.2rem;

      span {
        @include housky;
        @include picto('quote', 'before');
        @include picto('quote', 'after');
        font-size: 3em;
        margin-top: 0.15em;
        display: block;
        position: relative;
        width: max-content;
        margin-inline: auto;

        &::before,
        &::after {
          position: absolute;
          font-size: 0.3em;
        }

        &::before {
          left: -1em;
          top: 0;
        }

        &::after {
          right: -1em;
          bottom: 0;
          transform: rotate(180deg);
        }
      }
    }

    &__list {
      display: flex;
      max-width: 118rem;
      margin: 6.4rem auto;

      &__item {
        padding-inline: 1.6rem;
        margin-inline: 1.6rem;

        &:not(:first-child) {
          border-left: 0.1rem solid $color-grey-200;
        }
      }
    }
  }

  &__service {
    text-align: center;
    text-decoration: none;

    svg {
      width: 6rem;
      display: block;
      margin-inline: auto;
    }

    strong {
      display: block;
      margin-bottom: 2.4rem;
    }

    p {
      font-weight: 100;
    }

    &:hover {
      p {
        color: $color-grey-600;
      }
    }
  }
}

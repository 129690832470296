@import 'styles/mixins/media-queries';

.GiftcardForm {
  margin-block: 5rem;
  display: flex;
  flex-direction: column;
  gap: 3.6rem;

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__fields {
    width: 100%;
  }

  &__summary {
    width: 100%;
  }

  @include over-mobile {
    &__buttons {
      flex-direction: row;
      max-width: 70rem;
      margin-inline: auto;
    }
  }

  @include on-desktop {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4.8rem;

    &__fields {
      width: calc(100% - 45rem - 4.8rem);
      flex-grow: 1;
    }

    &__summary {
      width: 45rem;
      flex-shrink: 0;
    }
  }
}

@import 'styles/mixins/media-queries';

.Basdepage {
  text-align: center;
  margin: 1rem auto;

  p {
    font-size: 1rem;
  }
}

@import 'styles/variables/colors';

.ProductVariants {
  width: 100%;
  display: flex;
  font-size: 1.6rem;
  overflow-x: auto;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;

  a {
    display: block;
    text-decoration: none;
    color: $color-grey-400;
    margin: 0.4rem;

    &[disabled] {
      color: $color-grey-999;
      pointer-events: none;
    }
  }

  &[data-type='value'] {
    a {
      border: solid 0.1rem currentColor;
      line-height: 1.5em;
      padding: 0.25em;
    }
  }

  &[data-type='color'] {
    padding-bottom: 2.4rem;

    a {
      position: relative;
      border-radius: 100%;

      span:nth-child(1) {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 100%;
        display: block;
        background-size: cover;
        background-position: center center;
        box-sizing: content-box;
        border: 0.1rem solid $color-grey-000;
        box-shadow: 0 0 0 0.1rem $color-grey-200;

        &::after {
          content: '';
          position: absolute;
          border-radius: 100%;
          inset: 0.1rem;
          background-color: rgba($color-grey-000, 0.5);
        }
      }

      span:nth-child(2) {
        display: none;
      }

      &[disabled] {
        span:nth-child(1)::after {
          display: none;
        }

        span:nth-child(2) {
          display: block;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          color: $color-grey-500;
        }
      }
    }
  }
}

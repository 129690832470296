@import 'styles/mixins/media-queries';

.Associations {
  padding-bottom: 8rem;

  &__logos {
    max-width: 75rem;
    margin: auto;
    padding-bottom: 8rem;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__item {
      flex-shrink: 0;
      display: flex;

      img {
        margin: auto;
        width: 14rem;
        height: auto;
      }
    }
  }

  &__text {
    max-width: 120rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 5rem;

    strong {
      display: block;
      margin-bottom: 3rem;
    }
  }
}

@include over-mobile {
  .Associations {
    &__text {
      flex-direction: row;
      align-items: flex-start;

      img {
        max-width: 50%;
      }
    }
  }
}

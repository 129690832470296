@import 'styles/variables/colors.scss';

.MenuFilter {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  & & {
    padding-top: 0.4rem;
    padding-left: 0.8rem;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__button {
      width: 100%;
      text-align: left;
      padding: 0.8rem;
      background-color: $color-grey-000;

      &[data-active='true'] {
        background-color: $color-primary-01-100;
      }

      &:hover {
        background-color: $color-primary-01-200;
      }
    }
  }
}

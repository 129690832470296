@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';
@import 'styles/variables/colors';

.ParagraphFull {
  @include full-width();
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include on-mobile {
    grid-template-columns: 1fr;
  }

  &__left {
    grid-auto-flow: dense;
    direction: rtl;
    margin-top: 10rem;

    @include on-mobile {
      direction: ltr;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include on-mobile {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    &__images {
      display: flex;
      gap: 3rem;
    }
    &__text {
      text-align: center;
      max-width: 45rem;
      h3 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 3.2rem;
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 2rem;
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }

  @include on-mobile {
    flex-direction: column;
  }
}

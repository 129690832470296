@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/media-queries';

.StoreTeam {
  padding-block: 3.2rem;

  &__title {
    font-size: 2.6rem;
    font-weight: bold;
    margin-bottom: 3.2rem;
  }

  &__content {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;
  }

  &__block {
    position: relative;

    &--text {
      background-color: $color-primary-01-200;
      border-top-right-radius: 10rem;
      padding: 2.4rem;
      font-size: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--image {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    &__title {
      @include housky;
      font-size: min(20vw, 10rem);
      margin-top: 1.6rem;
      margin-bottom: 3.2rem;
    }
  }

  @include on-mobile {
    &__block {
      &--image {
        &:nth-of-type(2) {
          border-bottom-left-radius: 10rem;
        }
      }
    }
  }

  @include on-tablet {
    &__content {
      gap: 2.4rem;
      flex-direction: row;
      flex-wrap: wrap;
    }
    &__block {
      &--text {
        width: 100%;
        font-size: 1.8rem;
      }

      &--image {
        width: calc(50% - 1.2rem);

        &:nth-of-type(1) {
          border-bottom-left-radius: 10rem;
        }
      }
    }
  }

  @include on-desktop {
    &__content {
      gap: 3.2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__block {
      &--text {
        order: 3;
        grid-column: 2;
        grid-row: 1 / 3;
        font-size: 1.8rem;
        padding: 4.8rem 3.2rem;
      }

      &--image {
        aspect-ratio: 16/9;

        &:nth-of-type(2) {
          border-bottom-left-radius: 10rem;
        }
      }

      &__title {
        margin-bottom: 4.8rem;
      }
    }
  }
}

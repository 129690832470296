@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.Order {
  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    margin-top: 4rem;

    @include on-mobile {
      align-items: flex-start;
    }
  }

  &__bloc {
    width: 100%;
    display: grid;
    grid-template-columns: 95% 5%;
    align-items: center;

    @include on-mobile {
      align-items: flex-start;
      grid-template-columns: 90% 5%;
    }
    @include on-tablet {
      align-items: flex-start;
      grid-template-columns: 90% 5%;
    }
  }

  &__icon {
    width: 28px;
    height: 28px;
    border: 1px solid $color-grey-100;
    background: #fff;
    border-radius: 50%;
    padding-left: 0.4rem;
    margin-right: 3rem;
    margin-left: -1.3rem;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      background: #fff;
      width: 3rem;
      height: 1.2rem;
      position: absolute;
      top: -13px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      top: auto;
      bottom: -13px;
    }
  }

  &__detail {
    display: grid;
    grid-column-gap: 3rem;
    max-width: 100%;
    grid-template-columns: 20% 20% 12% 30%;

    @include on-mobile {
      grid-template-columns: 1fr;
    }

    &__cross,
    &__plus {
      width: 4rem;
      height: 4rem;
      border: 1px solid black;
      background: #fff;
      padding-left: 1rem;
      font-size: 1.8rem;
      padding-top: 0.4rem;
      cursor: pointer;
      &:hover {
        background: $color-grey-100;
        border: 1px solid $color-grey-100;
      }
    }
    &__validate,
    &__number,
    &__price,
    &__date {
      margin: 0 0 0.5rem;
      font-size: 1.8rem;
    }
    &__number {
      font-weight: 700;
    }

    &__validate {
      margin: 0;

      span {
        border: 1px solid #000;
        color: #000;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 0.25rem 0.75rem;
        font-size: 1.2rem;
      }
    }
  }

  &__body {
    padding-left: 6rem;
    margin-top: 4rem;

    @include on-mobile {
      padding-left: 4rem;
    }
  }
}

@import 'styles/variables/colors';

.ConsentOptin {
  margin-top: 2rem;
  margin-bottom: 5rem;

  a {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.85em;
    margin-left: 1ch;
    color: $color-grey-800;
  }
}

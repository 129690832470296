@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.CartItems {
  display: block;

  &__head {
    display: block;
    width: 100%;
    margin-bottom: 1.6rem;
    padding-bottom: 1.6rem;
    border-bottom: 0.1rem solid $color-grey-100;

    &__line {
      display: flex;
      gap: 1.6rem;
      align-items: center;
    }

    &__cell {
      white-space: nowrap;
      font-size: 1.3rem;
      font-weight: bold;

      &:first-child {
        text-align: left;
        width: 100%;
      }

      &:not(:first-child) {
        display: none;
      }
    }
  }

  &__body {
    display: block;
    width: 100%;

    &__line {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      align-items: center;
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid $color-grey-100;
    }

    &__cell {
      &:first-child {
        width: 100%;
      }

      &:not(:first-child) {
        width: calc(calc(100% - 3.2rem) / 3);
        text-align: center;

        &[data-label] {
          &::before {
            content: attr(data-label) ' :';
            display: block;
            font-size: 1.3rem;
            font-weight: bold;
            margin-bottom: 0.8rem;
          }
        }
      }
    }
  }

  @include on-desktop {
    &__head,
    &__body {
      &__line {
        flex-wrap: nowrap;
      }

      &__cell {
        &:first-child {
          flex-grow: 1;
        }

        &:not(:first-child) {
          display: block;
          flex-shrink: 0;

          &[data-label]::before {
            display: none;
          }
        }

        &:nth-child(2),
        &:nth-child(4) {
          width: 9rem;
        }
        &:nth-child(3) {
          width: 14rem;
        }
      }
    }
  }
}

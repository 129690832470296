@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';

.Banner {
  @include full-width(200rem);
  color: $color-grey-000;

  &__content {
    position: absolute;
    inset: 0;
    width: 80%;
    margin: auto;
    display: flex;
    gap: 1.6rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: normal;
    filter: drop-shadow(0 0 0.5em rgba($color-grey-999, 0.5));

    svg {
      width: min(80vw, 50rem);
      fill: currentColor;
    }

    span {
      font-size: min(5vw, 3.6rem);
      width: min(85vw, 60rem);
    }
  }

  @include over-mobile {
    &__content {
      svg {
        width: min(40vw, 52rem);
      }

      span {
        font-size: min(3vw, 3.6rem);
        width: min(80vw, 60rem);
      }
    }
  }
}

.address {
  background: white;
  padding: 3rem 3rem 5rem;
  text-align: left;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  p {
    font-size: 2rem;
    font-weight: 300;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.WorkshopCards {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  &__title {
    font-size: 2.8rem;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 1.6em;
    font-weight: bold;
  }

  &__content {
    display: grid;
    margin-top: 4rem;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  &__item {
    &__image {
      width: 100%;
      aspect-ratio: 7/4;
      object-fit: cover;
    }

    &__content {
      background-color: $color-primary-01-100;
      padding: 4rem;

      &__title {
        font-size: 2.2rem;
        margin-bottom: 1em;
        font-weight: bold;
      }

      &__text {
        font-size: 1.6rem;
      }

      &__button {
        max-width: 35rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4rem;
      }
    }
  }

  @include on-desktop {
    padding-top: 7rem;
    padding-bottom: 7rem;

    &__title {
      font-size: 3.6rem;
    }

    &__subtitle {
      font-size: 2.4rem;
    }

    &__content {
      margin-top: 7rem;
      gap: 3.2rem;
      grid-template-columns: 1fr 1fr;
    }

    &__item {
      &__content {
        padding: 7rem;

        &__title {
          font-size: 2.4rem;
        }

        &__text {
          font-size: 1.8rem;
        }
      }
    }
  }
}

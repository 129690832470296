@import 'styles/variables/colors';

.StandardPayment {
  position: relative;

  &__title {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 1em;
  }

  &__methods {
    border: 0.1rem solid $color-grey-100;

    &__item {
      &:not(:first-of-type) {
        border-top: 0.1rem solid $color-grey-100;
      }

      &__inner {
        padding: 1.6rem;
        display: flex;
        gap: 1.6rem;
        cursor: pointer;
      }

      &__checkbox {
        flex-shrink: 0;
        display: block;
        width: 2rem;
        height: 2rem;
        background-color: $color-grey-000;
        border: 0.1rem solid currentColor;
        position: relative;

        &:checked::after {
          content: '';
          position: absolute;
          inset: 25%;
          background-color: currentColor;
          border-radius: 100%;
        }
      }

      &__title {
        flex-grow: 1;
        font-weight: bold;

        svg {
          margin-right: 0.8rem;
        }
      }

      &__logos {
        flex-shrink: 0;
        display: flex;
        gap: 0.4rem;

        img {
          height: 2rem;
          width: auto;
        }
      }
    }
  }
}

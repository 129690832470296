@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';

.Ideas {
  display: flex;
  flex-direction: column;
  max-width: 144rem;
  gap: 1.6rem;

  &__block {
    position: relative;
    display: block;
    width: 100%;
    text-decoration: none;

    &__image {
      display: block;
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    &__button {
      position: absolute;
      bottom: 1.6rem;
      left: 1.6rem;
      right: 1.6rem;
      text-align: center;
      margin: auto;
      width: 100%;
      max-width: min(calc(100% - 3.2rem), 33rem);

      button {
        height: 5.6rem;
      }
    }
  }

  @include over-mobile {
    flex-direction: row;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    gap: 4.8rem;
  }

  @include on-big-screen {
    padding-left: 0;
    padding-right: 0;
    gap: 6rem;
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.Icons {
  margin-top: 6rem;
  margin-bottom: 6rem;

  &__title {
    display: block;
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 3rem;

    b {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  &__blocks {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    border: 0.1rem solid #f5f5f5;

    &__item {
      width: 100%;
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      gap: 0.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 0.1rem solid #f5f5f5;

      &:hover {
        background-color: #f2f2f2;
      }

      &__title {
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
      }

      :global {
        svg {
          width: 2em;
          height: 2em;
        }
      }
    }
  }
}

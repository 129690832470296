@import 'styles/mixins/media-queries';

.Offer {
  display: flex;
  gap: 3.2rem;
  flex-direction: column;
  margin-bottom: 4.8rem;

  &__item {
    display: block;

    & + & {
      margin-top: 3.2rem;
    }

    &__title {
      font-size: 2rem;
      margin-bottom: 1.6rem;
      font-weight: bold;

      &::before {
        content: '•';
        vertical-align: middle;
        font-size: 0.6em;
        margin-right: 1.5ch;
      }
    }

    &__text {
      font-size: 1.6rem;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }
  }

  @include over-mobile {
    flex-direction: row;

    &__column {
      &:nth-child(1) {
        width: auto;
        flex-grow: 1;
      }

      &:nth-child(2) {
        width: 32rem;
        flex-shrink: 0;
      }
    }
  }
}

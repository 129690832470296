@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.WorkshopsPictos {
  padding-block: 4.8rem;
  display: flex;
  gap: 4.8rem;
  overflow: auto;
  width: fit-content;
  margin-inline: auto;

  &__item {
    display: block;
    flex-shrink: 0;
    width: 8rem;
    font-size: 1.2rem;
    text-align: center;

    a {
      text-decoration: none;
    }

    &__icon {
      display: block;
      width: 60%;
      margin-inline: auto;
      aspect-ratio: 1/1;
      background-color: $color-primary-01-100;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.2em;
      margin-bottom: 1rem;
    }
  }

  @include on-desktop {
    &__item {
      width: 10rem;
      font-size: 1.4rem;
    }
  }
}

@import 'styles/mixins/media-queries';

.Banner {
  width: 100%;
  height: auto;
  margin-bottom: 2.4rem;

  @include on-desktop {
    margin-bottom: 3.2rem;
  }
}

@import '/styles/variables/colors.scss';

.SelectStore {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
  min-height: 28rem;

  &__input {
    position: relative;

    &__button {
      position: absolute;
      top: 0.1rem;
      right: 0.5rem;
      text-align: right;
      height: 4.2rem;
      padding: 0.5rem 1rem;
      background-color: $color-grey-000;
    }

    &__suggestions {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      background-color: $color-grey-000;

      &__item {
        font-size: 1.4rem;
        padding: 0.4rem;
        color: $color-grey-700;
        border: 0.1rem solid $color-grey-200;
        border-top: none;

        &:hover {
          background-color: $color-grey-100;
          color: $color-grey-900;

          button svg {
            color: $color-info-300;
          }
        }

        button {
          width: 100%;
          text-align: left;
          gap: 1rem;
          min-height: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          z-index: 2;

          svg {
            flex-shrink: 0;
            font-size: 1.5em;
            color: $color-grey-500;
          }
        }
      }
    }
  }

  &__count {
    background-color: $color-grey-100;
    padding: 1rem;
    line-height: 1;
  }

  &__stores {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      padding: 1rem;
      border: 0.1rem solid $color-grey-100;
      line-height: 1;

      &__name {
        font-weight: bold;
        font-size: 1.6rem;
        text-transform: uppercase;

        span {
          text-transform: none;
          font-weight: normal;
        }
      }

      &__stock {
        font-size: 1.2rem;
        font-weight: bold;
      }

      &__delivery {
        font-size: 1.6rem;
        font-weight: normal;
      }

      &__opening {
        font-size: 1.2rem;
        font-weight: normal;
      }
    }
  }
}

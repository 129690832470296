@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';
@import 'styles/fonts/Blacker/Blacker';

.WorkshopsThematics {
  text-align: center;
  padding-block: 3.2rem;

  &__title {
    font-family: Blacker, serif;
    font-size: 2.8rem;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 1.8rem;
    margin-bottom: 2em;
  }

  &__items {
    display: grid;
    gap: 2.4rem;
    grid-template-columns: 1fr;
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    overflow: hidden;

    li {
      overflow: hidden;

      &:first-child {
        border-top-left-radius: 7rem;
      }
    }
  }

  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;

    &:hover &__text {
      background-color: $color-primary-01-100;
    }

    &__image {
      aspect-ratio: 2/1;
      object-fit: cover;
    }

    &__text {
      flex-grow: 1;
      border: 0.1rem solid $color-grey-100;
      background-color: $color-grey-000;
      border-top: none;
      text-align: center;
      padding: 3.2rem;

      h3 {
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 1em;
      }

      p {
        max-width: 32rem;
        margin-inline: auto;
      }
    }
  }

  @include over-mobile {
    &__items {
      grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
    }
  }

  @include on-desktop {
    &__title {
      font-size: 3.8rem;
    }

    &__items {
      grid-template-columns: repeat(auto-fill, minmax(45rem, 1fr));
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.Steps {
  margin-top: 5rem;
  margin-bottom: 5rem;
  text-align: center;

  &__list {
    margin-top: 3rem;
    display: grid;
    width: 100%;
    counter-reset: item;
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;

    @include over-mobile {
      grid-template-columns: repeat(3, 1fr);
      gap: 12rem;
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
      justify-content: flex-start;
      counter-increment: item;

      &__image {
        width: 12rem;
        height: 12rem;
        border-radius: 100%;
        background-color: $color-primary-01-200;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% auto;
      }

      &__title {
        font-size: 2rem;
        font-weight: bold;

        &::before {
          content: counter(item) '.';
          margin-right: 1ch;
        }
      }
    }
  }

  &__Button {
    margin-top: 8rem;
  }
}

.Summary {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__clearAll {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  &__clearOne {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  svg {
    font-size: 0.65em;
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.CheckoutLoyalty {
  margin-block: 3.2rem;

  &__details {
    position: relative;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.6rem;

      h2 {
        font-weight: bold;

        span {
          font-weight: normal;
        }
      }

      p {
        font-weight: 100;

        span {
          font-weight: normal;
          color: $color-secondary-loyalty-primary;
        }
      }
    }
  }

  &__form {
    max-width: 65rem;
    margin-inline: auto;
    display: flex;
    gap: 3.2rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__logo {
      width: 12rem;
    }

    &__details {
      h2 {
        font-size: 1.8rem;
        font-weight: bold;
      }

      p {
        font-size: 1.6rem;
        font-weight: 100;
      }
    }
  }

  @include over-mobile {
    &__form {
      flex-direction: row;
      gap: 5rem;
    }
  }
}

@import 'styles/mixins/media-queries';

.TutoPictos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;

  &__item {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5ch;

    &__picto {
      font-size: 2.8rem;

      &[data-active='false'] {
        opacity: 0.25;
      }
    }

    &__description {
      margin-left: 0.5ch;
    }
  }

  @include over-mobile {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';

.SimpleTitle {
  text-align: center;

  &__title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 3rem;
  }
  &__text {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2.4rem;
    max-width: 73rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.contributions {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3rem;
  margin: 3rem auto 4rem;

  @include over-mobile {
    grid-template-columns: 25% 75%;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0 0 3.5rem;
      font-size: 2.8rem;
      line-height: 1.45;
      font-weight: 400;
    }
  }
}

@import 'styles/mixins/media-queries';

.CheckoutTemplate {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.4rem;
  margin-block: 3.2rem;

  @include on-tablet {
    grid-template-columns: 2fr 1fr;
  }

  @include on-desktop {
    grid-template-columns: 2.5fr 1fr;
  }

  @include on-big-screen {
    grid-template-columns: 2.75fr 1fr;
  }

  &__content {
    position: relative;
  }

  &__aside {
    position: relative;
  }
}

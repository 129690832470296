@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';

.Thumbnails {
  @include full-width;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &__vignette {
    position: relative;
    width: 100%;
    max-width: 130rem;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &[data-type='default'],
  &[data-type='occasions'],
  &[data-type='technique'] {
    background-color: $color-grey-000;
  }
  &[data-type='vignette'] {
    background-color: $color-grey-000;
  }

  &[data-type='default'] {
    [class='container'] {
      max-width: 118rem;
    }
  }

  &[data-type='supports'] {
    background-color: $color-primary-01-200;
  }

  &__title {
    font-size: 3rem;
    padding-top: 2rem;
    padding-bottom: 5rem;
    font-weight: bold;
    text-align: center;
  }
  &__thumbVignette,
  &__thumbs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    a {
      text-decoration: none;
    }
  }

  @include on-tablet {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    &__thumbVignette,
    &__thumbs {
      grid-template-columns: repeat(3, 1fr);
      gap: 2.5rem;
    }
  }

  @include on-desktop {
    padding-top: 3rem;
    padding-bottom: 3rem;

    &__thumbVignette {
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
    }

    &__thumbs {
      grid-template-columns: repeat(4, 1fr);
      gap: 3rem;
    }
  }

  &__button {
    margin-top: 3rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    a {
      border: 1px solid black;
      background: white;
      font-size: 1.2rem;
      padding: 1.9rem 3.7rem;
      text-decoration: none;
    }
  }
}

// Thumbnail items
.Thumbnail {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__image {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  &__title {
    z-index: 1;
    text-align: center;
  }

  [data-type='default'] & {
    border: 0.1rem solid $color-grey-100;

    .Thumbnail__image {
      aspect-ratio: 3/2.23;
    }

    .Thumbnail__title {
      font-size: 1.6rem;
      padding: 2rem 1rem;
    }

    &:hover {
      .Thumbnail__title {
        background-color: $color-primary-01-200;
      }
    }
  }

  [data-type='occasions'] & {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba($color-grey-999, 0.35);
    }

    .Thumbnail__title {
      position: absolute;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      color: $color-grey-000;
      font-weight: 700;
      font-size: 2.4rem;

      @include on-mobile {
        font-size: 2.2rem;
      }
    }
  }
  [data-type='vignette'],
  [data-type='supports'] & {
    .Thumbnail__title {
      position: absolute;
      left: 0.8rem;
      right: 0.8rem;
      bottom: 0.8rem;
      background-color: $color-grey-000;
      font-weight: 700;
      font-size: 1.2rem;
      padding: 1.2rem 0.8rem;
      text-transform: uppercase;
    }
  }
  [data-type='vignette'] & {
    .Thumbnail__title {
      position: absolute;
      left: 0.8rem;
      right: 0.8rem;
      bottom: 0.8rem;
      background-color: $color-grey-000;
      font-weight: 700;
      font-size: 1.2rem;
      padding: 1.2rem 0.8rem;
      text-transform: uppercase;
    }
  }

  [data-type='technique'] & {
    .Thumbnail__image {
      aspect-ratio: 16/9;
    }

    .Thumbnail__title {
      font-weight: 700;
      font-size: 1.6rem;
      padding: 1.2rem 0.8rem;
    }
  }
}

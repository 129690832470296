@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/mixins/typo';

.Login {
  @include full-width(200rem);
  padding: 4rem 2rem;
  background-color: $color-secondary-ss-pink;
  background-image: url('/images/login-background.jpg');
  box-shadow: inset 0 0 10rem rgba($color-grey-999, 0.25);
  background-size: cover;

  &__loader {
    display: flex;
    position: absolute;
    inset: 0;
    background-color: rgba($color-grey-999, 0.3);
    z-index: 1;

    &__picto {
      margin: auto;
      font-size: 10vw;
    }
  }

  &__card {
    max-width: 95rem;
    margin: auto;
    padding: 4rem 2rem 2rem 2rem;
    background-color: $color-grey-000;
    border-radius: 2rem;
    text-align: center;

    &__title {
      @include housky;
      font-size: 7rem;
      margin-bottom: 0.5em;
    }

    &__text {
      margin-bottom: 3em;
      font-size: 1.8rem;
      position: relative;
    }

    &__form {
      text-align: left;
      max-width: 46rem;
      margin: auto;
      position: relative;
      z-index: 1;

      fieldset {
        &:not(:first-of-type) {
          margin-top: 3rem;
        }

        legend {
          font-size: 2.2rem;
          margin-bottom: 1.6rem;
          font-weight: bold;

          span {
            margin-left: 1ch;
            font-size: 1.4rem;
            font-weight: normal;
            color: $color-grey-500;
          }
        }
      }

      &__password__button {
        font-size: 1.6rem;
      }

      &__button {
        max-width: 33rem;
        margin-top: 3rem;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      &__row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: stretch;
        margin-bottom: 0.5rem;
        gap: 1.6rem;

        & > * {
          width: 100%;
        }
      }

      &__mentions {
        max-width: 33rem;
        margin-top: 8rem;
        margin-bottom: 3rem;
        margin-left: auto;
        margin-right: auto;
        color: $color-grey-400;
        text-align: center;
        font-size: 1.2rem;

        a {
          text-decoration: none;
          color: $color-grey-700;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &__help {
        display: flex;
        gap: 0.25em;
        flex-wrap: wrap;
        margin-bottom: 1.6rem;

        &__label {
          font-weight: 1.6rem;
          font-weight: bold;
        }

        &__button {
          font-size: 1.2rem;
          margin-left: auto;
          text-decoration: underline;
        }

        &__value {
          width: 100%;
          font-style: italic;
          font-size: 1.8eem;
          color: $color-grey-500;
        }
      }

      &__loyalty {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        counter-reset: item;

        &__item {
          padding-left: 5rem;
          position: relative;
          display: flex;
          flex-direction: column;
          counter-increment: item;

          &::before {
            content: counter(item);
            font-size: 1.8rem;
            font-weight: bold;
            position: absolute;
            top: 0;
            left: 0;
            width: 4rem;
            height: 4rem;
            border-radius: 100%;
            background-color: $color-grey-999;
            color: $color-grey-000;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  @include on-desktop {
    padding: 8rem 0;

    &__card {
      padding: 6rem 12rem;

      &__title {
        font-size: 9rem;
      }

      &__text {
        font-size: 2.6rem;
      }

      &__form {
        max-width: none;
      }
    }
  }
}

@import 'styles/mixins/media-queries';

.infosLivraison {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 5rem;

  &__title {
    font-size: 2.2rem;
    font-weight: bold;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    img {
      display: inline-block;
      height: 4rem;
      width: auto;
      vertical-align: middle;
      margin-right: 1.5rem;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/picto';

.Newsletter {
  margin-bottom: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  &__content {
    background-color: $color-primary-01-200;
    padding: 2.4rem;

    &__title {
      @include housky;
      font-size: min(13vw, 9rem);
      margin-top: 1.6rem;
      margin-bottom: 3.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        position: relative;
        @include picto('quote', 'before');

        &::before {
          position: absolute;
          font-size: 0.3em;
        }

        &:nth-child(1)::before {
          left: -1em;
          top: -0.7em;
        }

        &:nth-child(2)::before {
          right: -1em;
          bottom: 0em;
          transform: scale(-1);
        }
      }
    }

    &__text {
      text-align: justify;
    }

    &__mentions {
      font-style: italic;
      font-size: 1.2rem;
      font-weight: 300;
      text-align: center;
      text-align: justify;
    }
  }

  @include over-mobile {
    flex-direction: row;
    align-items: stretch;

    &__image {
      width: 50%;
      order: 1;
      border-bottom-left-radius: 7rem;
    }

    &__content {
      padding: 3.2rem;
      width: 50%;
      order: 2;
      border-top-right-radius: 7rem;

      &__title {
        max-width: 55rem;
        margin-inline: auto;
      }
    }
  }
}

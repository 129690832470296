@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';
@import 'styles/common/container';

.Intro {
  &__title {
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-size: 2.8rem;
    font-weight: normal;
  }

  &__block {
    display: flex;
    gap: 3rem;
    flex-direction: column;

    & + & {
      margin-top: 3rem;
    }

    &__image {
      max-width: 44.5rem;
      margin: auto;
    }

    &__text {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      font-weight: 300;
      line-height: 1.875;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.ProductPlus {
  margin-bottom: 2rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 0.1rem solid $color-grey-100;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;

  &__picto {
    width: 2rem;
    height: 2rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    flex-shrink: 0;
    background-color: $color-primary-01-200;
  }
}

@include on-desktop {
  .ProductPlus {
    padding: 2rem 3rem;
    gap: 3rem;
    font-size: 1.8rem;

    strong {
      display: block;
    }

    &__picto {
      width: 4rem;
      height: 4rem;
      font-size: 1.6rem;
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';
@import 'styles/mixins/full-width';

.ContentTiles {
  @include full-width();
  padding-bottom: 2rem;
  background-color: $color-grey-100;

  h2 {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__subtitle {
    font-weight: 300;
    font-size: 2rem;
    line-height: 3.6rem;
    margin-bottom: 3rem;
    text-align: center;

    @include on-mobile {
      font-size: 1.6rem;
    }
  }

  &__slider {
    margin: auto;
    max-width: 130rem;
  }

  &__button {
    margin-top: 3rem;
    text-align: center;
    max-width: 33rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.blockInformation {
  background: $color-grey-100;
  padding: 2rem;
  flex-direction: column;
  height: 100%;
  h2 {
    font-size: 2.2rem;
    font-weight: 700;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    li {
      padding-bottom: 1rem;
      margin: 0 0 0.625rem;
      font-size: 1.5rem;
      color: $color-grey-500;
      font-weight: 300;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      &:not(:last-of-type) {
        border-bottom: 1px solid $color-grey-100;
      }

      strong {
        color: $color-grey-999;
        font-weight: 700;
        font-size: 2rem;
        text-align: right;
      }
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';
@import 'styles/mixins/typo';

.LoyaltyProgramCard {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__image {
    display: block;
    width: 100%;
    aspect-ratio: 6/2.3;
    object-fit: cover;
  }

  &__content {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    padding: 2rem 1rem;
    background-color: $color-grey-000;

    &__icon {
      display: block;
      height: 12rem;
      aspect-ratio: 1/1;
      object-fit: contain;
      margin: 2rem auto;

      .LoyaltyProgramCard--with-image & {
        height: 10rem;
        margin: 5rem auto;
      }
    }

    &__title {
      font-size: 2rem;
      font-weight: bold;
    }

    &__titleHousky {
      @include housky;
      font-size: 9rem;
      line-height: 0.4;
      color: $color-secondary-loyalty-primary;
    }

    &__subtitle {
      display: block;
      font-size: 2rem;

      &--block {
        margin-top: 1rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 0.3em;
        line-height: 1.6;
      }
    }

    &__text {
      width: 80%;
      max-width: 30rem;
      margin: 2rem auto;
      font-size: 1.6rem;
      line-height: 1.6;
      text-align: center;
    }

    &__cta {
      margin-top: 1rem;
      font-weight: bold;
    }
  }
}

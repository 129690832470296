@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';

.Categories {
  @include full-width;
  padding: 2rem;
  background-image: url('./background.jpg');

  &__list {
    max-width: 90rem;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 2rem;

    @include on-desktop {
      gap: 3rem;
    }

    &__item {
      color: $color-grey-000;
      background-color: $color-grey-999;
      text-align: center;
      text-align: center;
      padding-top: 4rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      max-width: calc(100vw - 4rem);

      &:first-child {
        border-top-left-radius: 10rem;
      }

      &__title {
        font-size: 2.4rem;
        padding-left: 5rem;
        padding-right: 5rem;
        font-weight: bold;
      }

      &__text {
        flex-grow: 1;
        font-size: 2rem;
        padding-left: 5rem;
        padding-right: 5rem;
      }

      &__image {
        margin-top: 1rem;
        display: block;
        width: 100%;
        aspect-ratio: 2/1;
        object-fit: cover;
      }
    }
  }

  &__legend {
    text-align: center;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 2.4rem;
    font-weight: bold;
  }
}

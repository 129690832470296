@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.modalites {
  h2 {
    font-size: 2.3rem;
    font-weight: 700;
    margin: 8rem 0 2.125rem;
  }

  &__items {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 3rem;

    span {
      font-weight: 500;
    }

    @include on-mobile {
      grid-template-columns: 1fr;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.OrderSummary {
  margin-top: 5rem;
  &__subtotal,
  &__loyalty,
  &__delivery,
  &__total,
  &__reductions p {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__reductions p {
    font-weight: bold;
    font-size: 1.7rem;
  }
  &__total {
    font-weight: bold;
    border-top: 1px solid $color-grey-100;
    font-size: 1.8rem;
  }
}

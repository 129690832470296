@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.Inscriptions {
  padding: 6rem 0;
  position: relative;

  &__blocks {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-columns: 1fr;
    justify-content: center;
    max-width: 118rem;
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;

    @include over-mobile {
      grid-template-columns: 1fr 1fr;
      grid-gap: 3.2rem;
    }
  }

  &__block {
    background: $color-primary-01-200;
    padding: 2.4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    align-items: center;
    justify-content: space-between;

    &__icon {
      width: 100%;
      max-width: 5.5rem;
      margin-top: 2.4rem;
      height: 100%;
    }

    &__title {
      font-size: 2rem;
      text-align: center;
    }

    &__text {
      text-align: center;
      color: $color-grey-500;
      font-size: 1.8rem;
    }

    &__cta {
      display: block;
      width: 100%;
      max-width: 33rem;
      margin: auto;
      text-decoration: none;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.WorkshopStorePlace {
  margin-top: 4rem;
  &__store {
    border: 1px solid $color-grey-200;
    padding: 1rem;

    &__icon {
      font-size: 3.6rem;
      margin-right: 2rem;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.Price {
  font-weight: normal;
  color: $color-grey-700;

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5em;

    & > * {
      flex-shrink: 0;
    }
  }

  &--small {
    font-size: 1.3rem;
  }

  &--small {
    font-size: 1.7rem;
  }

  &--regular {
    font-size: 2.1rem;
  }

  &--big {
    font-size: 2.4rem;
  }

  &__discount {
    order: 3;
    flex-shrink: 0;
    font-size: 0.6em;
    font-weight: bold;
    padding: 0.4rem 0.6rem;
    background-color: $color-danger-600;
    color: $color-grey-000;
    position: relative;

    &::before {
      content: '';
      width: 0.5em;
      height: 0.5em;
      background-color: inherit;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: -1;
    }

    & + .Price__original {
      order: 2;
      font-size: 0.65em;

      & + .Price__final {
        color: inherit;
      }
    }
  }

  &__original {
    text-decoration: line-through;
    font-size: 0.85em;

    & + .Price__final {
      color: $color-danger-600;
    }
  }

  &__ecopart {
    display: block;
    font-size: 0.5em;
    font-weight: 100;
  }

  @include over-mobile {
    &--small {
      font-size: 1.4rem;
    }

    &--small {
      font-size: 2rem;
    }

    &--regular {
      font-size: 2.4rem;
    }

    &--big {
      font-size: 3rem;
    }
  }
}

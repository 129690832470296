@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';
@import 'styles/mixins/full-width';

.Legend {
  @include full-width;
  font-size: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-weight: 100;
  color: $color-grey-400;
  border-top: 0.1rem solid $color-grey-100;

  @include on-desktop {
    font-size: 1.6rem;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.informations {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3rem;
  margin: 3rem auto 4rem;

  h2 {
    margin: 0 0 3.5rem;
    font-size: 2.8rem;
    line-height: 1.45;
    font-weight: 400;
  }

  @include over-mobile {
    grid-template-columns: 25% 75%;
  }
  &__blocs {
    &__formulaire {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
      @include over-mobile {
        grid-template-columns: repeat(2, 1fr);
      }

      h2 {
        margin: 0 0 3.5rem;
        font-size: 2.4rem;
        line-height: 1.45;
        font-weight: 400;
      }
    }
  }
  &__adress {
    margin-top: 2rem;
  }
  .show-input {
    text-align: center;
  }
}

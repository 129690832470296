@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.retour {
  h2 {
    font-size: 2.3rem;
    font-weight: 700;
    margin: 2rem 0 2.125rem;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
    grid-column-end: span 9;

    @include on-mobile {
      grid-template-columns: 1fr;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/mixins/typo';

.Univers {
  @include full-width();
  background-color: $color-primary-01-100;
  padding-top: 4rem;
  padding-bottom: 2rem;

  &__container {
    max-width: 144rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__title {
    @include housky;
    font-size: 10rem;
    text-align: center;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    margin-bottom: 1.6rem;
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 300;
    text-align: center;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }

  &__slider {
    &__item {
      display: block;
      position: relative;
      overflow: hidden;

      &:hover &__image {
        transform: scale(1.03);
      }

      &__image {
        display: block;
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        transition: transform 0.2s ease;
      }

      &__button {
        position: absolute;
        bottom: 2.4rem;
        left: 2.4rem;
        right: 2.4rem;
      }
    }
  }

  @include over-mobile {
    padding-top: 8rem;

    &__container {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 6.4rem;
    }

    &__title {
      flex-grow: 1;
      font-size: 13rem;
      text-align: left;
    }

    &__subtitle {
      width: 45%;
      font-size: 2.4rem;
      text-align: left;
    }

    &__slider {
      width: 100%;

      &__item[data-is-last='true'] {
        border-top-right-radius: 30%;
      }
    }
  }
}

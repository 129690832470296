@import 'styles/variables/colors.scss';

.WorkshopTile {
  width: 100%;
  max-width: 45rem;
  position: relative;

  a {
    text-decoration: none;
    display: block;
  }

  &__header {
    display: flex;
    position: relative;
    aspect-ratio: 1/1;
    padding: 17.5%;
    background-color: $color-grey-100;
    transition: background-color 0.2s ease;

    [small] & {
      padding: 5rem 1.5rem;
      aspect-ratio: 1/1.35;
    }

    .WorkshopTile a:hover &,
    .WorkshopTile a:focus & {
      background-color: $color-primary-01-200;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 1.6rem;
    justify-content: center;
    align-items: center;
  }
}

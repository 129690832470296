@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/common/container';
@import 'styles/mixins/full-width';

.HeadBand {
  position: relative;
  background-color: $color-primary-01-200;
  background-image: url('./background.png');
  background-size: 200% auto;
  text-align: center;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  &__title {
    @include housky;
    font-size: 7rem;

    span {
      @include picto('quote', 'before');
      position: relative;

      &::before {
        position: absolute;
        font-size: 0.3em;
        left: -1em;
        top: 0;
      }
    }
  }

  &__text {
    font-weight: 100;
    margin-top: 1.6rem;

    p {
      font-size: 1.8rem;
    }
  }

  &__image {
    width: 100%;
    margin: -2rem;
    max-width: 40rem;
    aspect-ratio: 1/1;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }

    &[data-position='right'] {
      display: none;
    }
  }

  @include on-tablet {
    max-width: calc(100% - 20rem);
    margin: 3rem auto;
    padding: 2rem 12rem;
    background-size: cover;

    &__image {
      display: block;
      position: absolute;
      margin: auto;
      width: 20rem;
      top: 50%;

      &[data-position='left'] {
        left: 0;
        transform: translate(-50%, -50%);
      }

      &[data-position='right'] {
        display: block;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  @include on-desktop {
    max-width: 108rem;
    margin: 3rem auto;
    padding: 5rem 28rem;
    background-size: cover;

    &__title {
      font-size: 9rem;
    }

    &__image {
      display: block;
      position: absolute;
      margin: auto;
      width: 25rem;
      top: 50%;

      &[data-position='left'] {
        left: 0;
        transform: translate(-25%, -50%);
      }

      &[data-position='right'] {
        display: block;
        right: 0;
        transform: translate(25%, -50%);
      }
    }
  }

  @include on-big-screen {
    max-width: 132rem;
    margin: 3rem auto;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.orders {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3rem;
  margin: 3rem auto 4rem;

  @include over-mobile {
    grid-template-columns: 25% 75%;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0 0 3.5rem;
      font-size: 2.8rem;
      line-height: 1.45;
      font-weight: 400;
    }
  }

  h3 {
    margin: 3rem 0 2.125rem;
    font-size: 2.4rem;
  }

  &__list {
    margin-top: 4rem;
    border-left: 1px solid $color-grey-100;
    padding-bottom: 4rem;

    &__item {
      margin-top: 4rem;
    }
  }
}

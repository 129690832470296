@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.ProductATC {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 2rem;
  z-index: 0;
  position: relative;

  &__ghost {
    display: none;
  }

  &__quantity {
    width: 40%;
  }

  &__button {
    flex-grow: 1;
  }

  @include on-mobile {
    &[data-fixed='true'] {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      padding: 1rem;
      margin: 0;
      background-color: $color-grey-000;
      box-shadow: 5px 5px 15px 5px $color-grey-999;

      & + .ProductATC__ghost {
        display: block;
      }
    }
  }
}

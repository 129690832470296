@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';
@import 'styles/mixins/full-width';

.MoreIdeas {
  @include full-width();
  text-align: center;
  background: $color-primary-01-200;
  margin-top: 6rem;
  padding: 9rem 2rem 5rem;

  &__container {
    max-width: 118rem;
    margin: auto;
  }

  &__title {
    font-size: 2.8rem;
    margin-bottom: 4rem;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 100;
    margin-bottom: 6rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10rem;
    column-gap: 3rem;
    margin-top: 15rem;
  }

  &__item {
    background-color: $color-grey-000;
    border: 1rem solid $color-grey-000;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 2rem;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: $color-primary-01-300;
    }

    &__image {
      display: block;
      width: 110%;
      max-width: 30rem;
      aspect-ratio: 4/2.7;
      object-fit: cover;
      border-radius: 1rem;
      margin-bottom: -2rem;
      margin-left: -5%;
      margin-right: -5%;
    }

    &:nth-child(odd) &__image {
      transform: rotate(-15deg) translate(5%, -40%);
    }

    &:nth-child(even) &__image {
      transform: rotate(15deg) translate(-5%, -40%);
    }

    &__title {
      font-size: 2.2rem;
    }

    &__subtitle {
      font-size: 1.8rem;
      font-weight: 100;
      width: 70%;
      margin: auto;
    }
  }

  @include over-mobile {
    &__title {
      font-size: 3rem;
    }

    &__text {
      width: 60%;
      font-size: 1.8rem;
      margin: 4rem auto;
    }
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

$nbLines: 2;

.WorkshopTileTitle {
  width: 60%;
  margin-inline: auto;
  padding-left: 1rem;
  font-size: 1.8rem;
  line-height: 1.25em;
  height: $nbLines * 1.25em;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $nbLines;
  -webkit-box-orient: vertical;

  [small] & {
    width: 100%;
    padding-left: 0;
    height: auto;
    margin-bottom: 1rem;
  }
}

@import 'styles/variables/colors';

.CheckoutSteps {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -1.6rem;
  margin-bottom: 3.2rem;
  counter-reset: step;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    height: 0.1rem;
    width: 100%;
    background-color: $color-grey-100;
    z-index: -1;
  }

  &__link {
    counter-increment: step;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1em;
    flex-shrink: 0;
    background-color: $color-grey-000;
    font-size: 0;
    padding-left: 1.6rem;

    &::before {
      content: counter(step);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      width: 2em;
      height: 2em;
      color: $color-grey-000;
      background-color: $color-grey-900;
    }

    &[data-active='true'] {
      font-size: 1.6rem;
      font-weight: bold;
      margin-right: auto;
      padding-right: 1.6rem;
      min-width: 15rem;
    }

    &[data-disabled='true'] {
      pointer-events: none;
      cursor: not-allowed;

      &::before {
        background-color: $color-grey-500;
      }
    }
  }
}

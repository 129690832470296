@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.Details {
  background-color: $color-primary-01-200;

  &__content {
    padding-top: 8rem;
    padding-bottom: 8rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-bottom: 4rem;

    &__item {
      h3 {
        font-size: 2.2rem;
        font-weight: bold;

        &::before {
          content: '•';
          display: inline-block;
          vertical-align: 10%;
          font-size: 0.75em;
          margin-right: 0.5em;
        }
      }

      p {
        font-size: 1.8rem;
      }
    }
  }

  &__discount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    strong {
      display: block;
      font-size: 3.6rem;
      font-weight: bold;
    }

    p {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 2.2rem;
    }

    img {
      width: 80%;
      margin-top: 4rem;
      max-width: 26rem;
    }
  }
}

@include on-desktop {
  .Details {
    &__content {
      padding: 8rem;
      flex-direction: row;
      gap: 0;
      align-items: stretch;
    }

    &__list {
      width: 50%;
      min-width: 50rem;
      margin-bottom: 0;
      padding-right: 4rem;
      margin-right: 4rem;
      border-right: 0.1rem solid currentColor;
    }

    &__discount {
      width: 35%;
      max-width: calc(100% - 50rem);
      margin: auto;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.WorkshopReinsurrance {
  padding-top: 5rem;
  padding-bottom: 5rem;
  max-width: 118rem;
  margin-left: auto;
  margin-right: auto;

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 30rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.6rem;
    gap: 2.4rem;
    padding: 4rem 2rem;

    &:not(:first-of-type) {
      border-top: 0.1rem solid $color-grey-100;
    }

    &__picto {
      flex-shrink: 0;
      font-size: 4em;
    }
  }

  @include over-mobile {
    &__items {
      flex-direction: row;
      max-width: none;
    }

    &__item {
      flex-direction: column;
      padding: 2rem 7rem;
      text-align: center;

      &:not(:first-of-type) {
        border-top: none;
        border-left: 0.1rem solid $color-grey-100;
      }
    }
  }
}

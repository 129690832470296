.GiftcardPayment {
  margin-bottom: 3.2rem;

  &__title {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 1em;
  }

  &__form {
    margin-top: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;

    &__mentions {
      font-size: 1.3rem;
      font-weight: bold;
      font-style: italic;
    }
  }
}

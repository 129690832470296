@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.PLP {
  padding-block: 3.2rem;

  &__title {
    font-size: 2rem;
    font-weight: 700;

    small {
      font-size: 0.65em;
      font-weight: normal;
      color: $color-grey-500;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__column {
      width: 100%;
      flex-shrink: 0;
    }

    &__buttons {
      display: flex;
      gap: 1.6rem;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 1.6rem;
      margin-left: auto;

      &__filter,
      &__sort {
        width: 100%;
        max-width: 18rem;
      }
    }
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    &__column {
      position: relative;
      width: auto;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 2.4rem;

    button:hover {
      text-decoration: underline;
    }
  }

  @include on-desktop {
    &__title {
      font-size: 2.4rem;
    }

    &__header {
      flex-wrap: nowrap;
      gap: 2.4rem;
      margin-bottom: 2.4rem;

      &__column {
        width: auto;
        flex-grow: 1;
      }
    }

    &__content {
      gap: 2.4rem;

      @media screen and (max-width: 1250px) {
        grid-template-columns: 1fr 2fr;
      }

      @media screen and (min-width: 1250px) {
        grid-template-columns: 1fr 3fr;
      }
    }

    &__empty {
      padding-block: 8rem;
    }
  }
}

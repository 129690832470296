@import 'styles/variables/colors';

.History {
  max-width: 118rem;
  margin-inline: auto;

  &__loader {
    height: 20rem;
    display: flex;

    &__picto {
      margin: auto;
      font-size: max(5vw, 7rem);
    }
  }
}

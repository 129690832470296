@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.giftsCards {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3rem;
  margin: 3rem auto 4rem;

  @include over-mobile {
    grid-template-columns: 25% 75%;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0 0 2rem;
      font-size: 2.8rem;
      line-height: 1.45;
      font-weight: 400;
    }
  }
  &__getFile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;
  }
  &__download {
    width: 30rem;
  }
  &__tableGiftcards {
    margin-top: 4rem;
    &__body,
    &__head {
      display: grid;
      font-weight: 700;
      grid-template-columns: 10% 40% 20% 15% 20%;
      align-items: center;
      border: none;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
    &__head {
      background-color: $color-grey-100;
    }
  }
}

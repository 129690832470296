@import 'styles/mixins/media-queries.scss';

@mixin setGridColumns($mobile, $tablet, $desktop) {
  @include on-mobile {
    gap: 1.6rem;
    grid-template-columns: repeat(auto-fill, minmax(#{$mobile}rem, 1fr));
  }

  @include on-tablet {
    gap: 1.6rem;
    grid-template-columns: repeat(auto-fill, minmax(#{$tablet}rem, 1fr));
  }

  @include on-desktop {
    gap: 2.4rem;
    grid-template-columns: repeat(auto-fill, minmax(#{$desktop}rem, 1fr));
  }
}

.Items {
  display: grid;

  &[data-type='search'],
  &[data-type='products'],
  &[data-type='workshops'],
  &[data-type='marques'] {
    @include setGridColumns(16, 25, 25);
  }

  &[data-type='tutorials'],
  &[data-type='recipes'],
  &[data-type='tendances'] {
    @include setGridColumns(24, 26, 28);
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';

.Error404 {
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 10rem;

  &__title {
    @include housky;
    @include picto('underline');
    font-size: 7.5rem;
    line-height: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::after {
      text-align: center;
      font-size: 12rem;
      margin-top: -0.3em;
      color: $color-primary-01-200;
    }
  }

  &__subtitle {
    font-size: 2rem;
    font-weight: 100;
    margin-bottom: 3rem;
  }

  &__button {
    display: block;
    max-width: 33rem;
    margin: auto;
    text-decoration: none;
  }
}

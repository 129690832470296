@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';
@import 'styles/mixins/full-width';

.BrandBanner {
  position: relative;
  @include full-width();

  &__title {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 2.4rem;
    @extend .container;
  }

  &__picture {
    display: block;
    width: 100%;
  }

  @include over-mobile {
    &__title {
      font-size: 2.8rem;
    }
  }
}

.subtitle {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    font-size: 2em;
  }
}

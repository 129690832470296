@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/common/container';
@import 'styles/mixins/typo';

.Recompenses {
  @include full-width();
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url('./background.webp');
  background-size: min(100%, 200rem);
  background-position: center center;

  &__container {
    @extend .container;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center center;
    grid-gap: 2rem;
  }

  &__link {
    display: block;
    text-align: center;
    max-width: 33rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    text-decoration: none;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/typo';

.TitleWithHousky {
  text-align: center;
  line-height: 2.85rem;
  max-width: 800px;
  margin: 6rem auto auto;

  h1 {
    font-size: 3.2rem;
    line-height: 4rem;
    font-weight: bold;

    @include on-mobile {
      font-size: 2.7rem;
      padding: 0 2rem;
    }
  }

  span {
    @include housky;
    font-size: 9rem;
    text-align: center;
    margin-top: 2rem;
    display: block;

    @include on-mobile {
      font-size: 8rem;
      margin-top: 0;
    }
  }

  p {
    margin-top: 3rem;
    margin-bottom: 6rem;
    font-size: 18px;
    font-weight: 300;
    line-height: 33.75px;
  }
}

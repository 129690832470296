@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/full-width';
@import 'styles/mixins/typo';

.OurUnivers {
  max-width: 144rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  justify-content: center;

  &__title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  &__subtitle {
    font-weight: 300;
    font-size: 2.2rem;
    line-height: 3.6rem;
    margin-bottom: 4rem;
  }

  &__slider {
    &__item {
      display: block;
      position: relative;
      overflow: hidden;

      &:hover &__image {
        transform: scale(1.03);
      }

      &__image {
        display: block;
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        transition: transform 0.2s ease;
      }

      &__button {
        position: absolute;
        bottom: 2.4rem;
        left: 2.4rem;
        right: 2.4rem;
      }
    }
  }

  @include over-mobile {
    padding-top: 8rem;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6.4rem;

    &__slider {
      width: 100%;
    }
  }
}

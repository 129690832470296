@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/hide-scrollbar';

.categories {
  margin-top: 4rem;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  ul {
    display: flex;
    overflow: auto;
    @include hide-scrollbar;

    li {
      flex-shrink: 0;
      display: flex;

      a {
        padding: 1rem;
        margin: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        text-decoration: none;
        border: 0.1rem solid currentColor;
        transition: background-color 0.2s linear, border-color 0.2s linear;
        outline-offset: 0.5rem;

        &:hover {
          background-color: $color-grey-100;
          border-color: $color-grey-100;
        }
      }
    }
  }

  @include over-mobile {
    ul {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';
@import 'styles/common/container';

.WorkshopStickyBanner {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $color-grey-100;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 1;

  &__bloc {
    display: flex;
    @extend .container;
    align-items: center;
    gap: 3.2rem;
    justify-content: space-between;

    &__title {
      display: flex;
      gap: 2rem;
      align-items: center;
      img {
        width: 7rem;
        aspect-ratio: 1/1;
        object-fit: contain;
      }

      p {
        font-size: 2rem;
        font-weight: bold;
      }
    }
    svg {
      font-size: 1.25em;
      margin-left: 1rem;
    }
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.BlocRetours {
  padding: 6rem 5.25rem 1.25rem;
  text-align: center;
  background: #f2f2f2;
  h2 {
    font-size: 2.4rem;
    font-weight: 700;
    width: 60%;
    margin: 0 auto 3rem;
    text-align: center;
  }
  &__text {
    font-size: 1.9rem;
    font-weight: 400;
    text-align: center;
    margin: 0 0 2rem;
  }

  &__button a {
    display: flex;
  }
}

@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.ProductInfos {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > *:only-child {
      margin-right: auto;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    flex-grow: 1;
  }

  &__flag {
    flex-shrink: 0;
  }

  &__price {
  }

  &__reviews {
    display: flex;
    gap: 1rem;
    font-size: 1.4rem;

    button {
      text-decoration: underline;
    }
  }
}

@include over-mobile {
  .ProductInfos {
    &__block {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: 2.6rem;
    }

    &__reviews {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}

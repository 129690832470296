@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.Colors {
  margin-top: 6rem;
  margin-bottom: 6rem;

  &__title {
    display: block;
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 3rem;

    b {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  &__blocks {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include on-tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include on-desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      padding: 2rem;
      &:nth-child(odd) {
        background-color: #f9f9f9;
      }

      &__title {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 1em;
      }
    }
  }

  &__list {
    &__item {
      display: flex;
      &:not(:first-of-type) {
        margin-top: 0.25rem;
      }

      i {
        display: block;
        width: 3rem;
        border: 0.1rem solid $color-primary-02-200;
        aspect-ratio: 1/1;
        cursor: pointer;

        &:hover {
          transform: scale(1.15);
        }
      }

      h3 {
        font-size: 1.6rem;
        font-weight: bold;
        flex-grow: 1;
        margin-left: 1rem;
      }
    }
  }
}

@import 'styles/mixins/media-queries';
@import 'styles/variables/colors';

.Item {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  justify-content: flex-start;

  &__delete {
    font-size: 1.4rem;
  }

  &__image {
    width: 7rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
  }

  &__details {
    position: relative;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.25em;
  }

  &__delivery {
    font-size: 1.2rem;
    color: $color-grey-500;
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    &__method {
      display: flex;
      gap: 0.5em;
      align-items: flex-start;

      svg {
        margin-top: 0.25em;
      }

      &[data-available='false'] svg {
        color: $color-danger-500;
      }

      &[data-available='true'] svg {
        color: $color-success-500;
      }
    }
  }
}

@import 'styles/mixins/media-queries';

.Text {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 8rem;

  p {
    max-width: 86rem;
    margin: auto;
    font-size: 2.2rem;
  }
}

@include on-desktop {
  .Text {
    p {
      font-size: 3rem;
    }
  }
}

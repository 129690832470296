@import 'styles/variables/colors';

.DeliveryMethods {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 3.2em;

  &__item {
    position: relative;
    border: 0.1rem solid $color-grey-100;
    transition: transform 0.2s ease;

    &[data-active='true'] {
      border-color: currentColor;

      .DeliveryMethods__item__details {
        display: block;
      }
    }

    &:hover {
      transform: scale(1.005);
    }

    &__inner {
      padding: 1.6rem;
      display: flex;
      gap: 1.6rem;
      cursor: pointer;
    }

    &__checkbox {
      flex-shrink: 0;
      display: block;
      width: 2rem;
      height: 2rem;
      background-color: $color-grey-000;
      border: 0.1rem solid currentColor;
      position: relative;

      &:checked::after {
        content: '';
        position: absolute;
        inset: 25%;
        background-color: currentColor;
        border-radius: 100%;
      }
    }

    &__infos {
      flex-grow: 1;

      &__title {
        font-weight: bold;
      }

      &__image {
        width: 8rem;
        display: block;
      }

      &__subtitle {
        font-size: 1.3rem;
        color: $color-grey-400;
      }
    }

    &__price {
      flex-shrink: 0;
      text-transform: uppercase;
    }

    &__details {
      display: none;
      border-top: 0.1rem solid currentColor;
      margin-inline: 5rem;
      margin-block: 1.6rem;
      padding-top: 1.6rem;
    }
  }
}

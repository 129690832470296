.AdressList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;

  &__title {
    font-weight: bold;
    font-size: 1.6rem;
  }
}

@import 'styles/variables/colors.scss';

.CheckboxesFilter {
  display: block;

  &__search {
    display: block;
    margin-bottom: 1.6rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    &__item {
      width: 100%;

      &[data-active='true'] {
        background-color: $color-primary-01-100;
      }

      &:hover {
        background-color: $color-primary-01-200;
      }

      &__checkbox {
        padding: 0.8rem;
      }
    }
  }
}
